<template>
    <v-btn
        :loading="loadingBtn"
        @click="downlmoadFileEndesa(offreId)"
        depressed :color="$colors[0]" class="ma-auto text-capitalize text-white">
        <v-icon>mdi-file-upload</v-icon>
    </v-btn>
</template>
<script>
import axios from "axios"
export default {
    name:'endesaGaz',
    props:['offreId'],
    data(){
        return{
            loadingBtn:false,
        }
    },
    methods:{
        async downlmoadFileEndesa(offreId){
            this.loadingBtn = true;
                await axios({
                        url: process.env.VUE_APP_URL_API_CLIENT + 'downloadFilesEndesa/',
                        method: 'POST',
                        data: {'offreId': offreId,
                                'token': this.getToken('token'),
                            },
                    }).then( async (res) => {
                        if (res.data.result === 'permission') {
                            localStorage.setItem('vueAfficher', 'particulier')
                            localStorage.removeItem('vendeurName')
                            localStorage.removeItem('typeUser')
                            localStorage.removeItem('token')
                            this.$router.push('/login')
                        }
                        if(res.data.result == true){
                            if(res.data.file1 != false){
                                var aElement = document.createElement('a');
                                aElement.setAttribute('download', this.numCompteur+'_endesa_condition_general.pdf');
                                var href = res.data.file1
                                aElement.href = href;
                                aElement.setAttribute('target', '_blank');
                                aElement.click();
                                //window.open(res.data.file1, '_blank');
                            }
                            if(res.data.file2 != false){
                                var aElement = document.createElement('a');
                                aElement.setAttribute('download', this.numCompteur+'_endesa_condition_particulier.pdf');
                                var href =res.data.file2
                                aElement.href = href;
                                aElement.setAttribute('target', '_blank');
                                aElement.click();
                                //window.open(res.data.file2, '_blank');
                            }
                            
                        }else{

                        }
                    }).catch((err)=>{
                        console.log(err)
                    })
                this.loadingBtn = false;
            },
    },
        
}
</script>