<template>
  <div>
    <Loading
      v-if="loadingDialogGlobal"
      :dialogProps="loadingDialogGlobal"
      :messageDialogeProps="messageGlobal"
      :loadingProps="loadingGlobalValue"
      :errorProps="errorGlobalValue"
      @closeDialog="loadingDialogGlobal = $event"
    />
    <v-dialog v-model="dialogSheetFilter" width="500">
      <v-card height="100%">
        <v-toolbar :color="$colors[0]" dark>
          <v-card-title
            class="d-flex justify-content-center text-body-3 text-lg-h5 align-items-center"
          >
            <span>Téléchargement d'un Rapport</span
            ><v-icon class="ml-2">mdi-file-excel-outline</v-icon>
          </v-card-title>
        </v-toolbar>
        <div style="height: 100%" class="pa-4 d-flex flex-column">
          <v-form ref="formCreationExcel">
            <div class="d-flex flex-column justify-center">
              <v-select
                v-model="selectedVendeur"
                :items="vendeurs"
                item-text="nomComplet"
                item-value="id"
                label="Choisir un vendeur"
                :rules="[rules.requiredVendeur]"
                outlined
              ></v-select>
              <v-btn
                style="color: #fff;background: #FF6600;"
                @click="createSheet(selectedVendeur)"
                >Création de Rapport</v-btn
              >
            </div>
          </v-form>
        </div>
      </v-card>
    </v-dialog>
    <datatable
      id="table-validation-cotation"
      class="ma-lg-8 mx-4"
      :columns="columns"
      :sortKey="sortKey"
      :sortOrders="sortOrders"
      @sort="sortBy"
      @search="rechercheData"
    >
      <template v-slot:header>
        <div style="width: 200px" class="float-end container-div mr-2">
          <select
            v-model="filterStatus"
            class="form-control"
            id="sel1"
            @change="filterByStatus"
          >
            <option value="tout">Toutes les demandes</option>
            <option value="1">acceptée</option>
            <option value="0">en attente</option>
            <option value="2">refusée</option>
          </select>
        </div>
      </template>
      <template v-slot:export>
        <div class="float-end">
          <v-btn
            style="color: #fff;background: #FF6600;"
            @click="openDialogSheetFilter()"
          >
            <span>Rapport</span>
            <v-icon dense class="ml-2">mdi-file-excel-outline</v-icon>
          </v-btn>
        </div>
      </template>
      <template v-slot:body>
        <tbody
          class="text-center"
          v-if="projects.length > 0 && loadingTable == false"
        >
          <tr v-for="(project, index) in projects" :key="project.id">
            <td>{{ project.date }}</td>
            <td>{{ project.Commerciale }}</td>
            <td>
              <v-tooltip v-if="project.Raison.length >= 15" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <small class="td-table" v-bind="attrs" v-on="on">
                    <router-link
                      :to="{
                        path: '/les-profils-societes',
                        query: { id: project.idSociete },
                      }"
                      >{{
                        project.Raison.substring(0, 20) + "..."
                      }}</router-link
                    >
                  </small>
                  <small>{{ project.siret }}</small>
                </template>
                <span>{{ project.Raison }}</span>
              </v-tooltip>
              <small class="td-table" v-else>
                <router-link
                  :to="{
                    path: '/les-profils-societes',
                    query: { id: project.idSociete },
                  }"
                  >{{ project.Raison }}</router-link
                >
                <small>{{ project.siret }}</small>
              </small>
            </td>
            <td>
              <div v-if="project.typeDemande == 'ELEC'" class="td-table">
                <v-icon color="orange darken-1" class="mr-2"
                  >mdi-lightning-bolt-circle</v-icon
                >{{ project.typeDemande }}
              </div>
              <div v-else class="td-table">
                <v-icon color="blue darken-4" class="mr-2"
                  >mdi-fire-circle</v-icon
                >{{ project.typeDemande }}
              </div>
            </td>
            <td class="text-center">
              <div style="max-width: 200px">
                <small>{{ spliteList(project.numCompteur)[0] }}</small>
                <v-icon
                  v-if="spliteListSlice(project.numCompteur).length > 0"
                  data-toggle="collapse"
                  :data-target="'#multiCollapseCompteur' + index"
                  aria-expanded="false"
                  :aria-controls="'multiCollapseCompteur' + index"
                  right
                >
                  mdi-menu-down
                </v-icon>
                <div
                  class="collapse multi-collapse"
                  :id="'multiCollapseCompteur' + index"
                >
                  <div>
                    <small v-for="c in spliteListSlice(project.numCompteur)">
                      {{ c }}
                    </small>
                  </div>
                </div>
              </div>
            </td>
            <td class="text-center">
              <div style="max-width: 200px">
                <div
                  v-if="
                    project.fileUrl1 != '' &&
                    project.fileUrl1 != 'undefined' &&
                    project.fileUrl1 != null
                  "
                >
                  <small>
                    <a :href="project.fileUrl1" target="_blank"
                      ><span class="text-body-4 blue--text"
                        >Pièce jointe 1</span
                      ></a
                    >
                  </small>
                  <v-icon
                    class="ml-2 ml-lg-0"
                    v-if="
                      project.fileUrl2 != '' &&
                      project.fileUrl2 != 'undefined' &&
                      project.fileUrl2 != null
                    "
                    data-toggle="collapse"
                    :data-target="'#multiCollapseFile' + index"
                    aria-expanded="false"
                    :aria-controls="'multiCollapseFile' + index"
                    right
                  >
                    mdi-menu-down
                  </v-icon>
                </div>
                <div
                  class="collapse multi-collapse"
                  :id="'multiCollapseFile' + index"
                >
                  <div
                    v-if="
                      project.fileUrl2 != '' &&
                      project.fileUrl2 != 'undefined' &&
                      project.fileUrl2 != null
                    "
                  >
                    <small>
                      <a :href="project.fileUrl2" target="_blank"
                        ><span class="text-body-4 blue--text"
                          >Pièce jointe 2</span
                        ></a
                      >
                    </small>
                  </div>
                  <div
                    v-if="
                      project.fileUrl3 != '' &&
                      project.fileUrl3 != 'undefined' &&
                      project.fileUrl3 != null
                    "
                  >
                    <small>
                      <a :href="project.fileUrl3" target="_blank"
                        ><span class="text-body-4 blue--text"
                          >Pièce jointe 3</span
                        ></a
                      >
                    </small>
                  </div>
                  <div
                    v-if="
                      project.fileUrl4 != '' &&
                      project.fileUrl4 != 'undefined' &&
                      project.fileUrl4 != null
                    "
                  >
                    <small>
                      <a :href="project.fileUrl4" target="_blank"
                        ><span class="text-body-4 blue--text"
                          >Pièce jointe 4</span
                        ></a
                      >
                    </small>
                  </div>
                  <div
                    v-if="
                      project.fileUrl5 != '' &&
                      project.fileUrl5 != 'undefined' &&
                      project.fileUrl5 != null
                    "
                  >
                    <small>
                      <a :href="project.fileUrl5" target="_blank"
                        ><span class="text-body-4 blue--text"
                          >Pièce jointe 5</span
                        ></a
                      >
                    </small>
                  </div>
                </div>
              </div>
              <div style="max-width: 200px">
                <div v-if="project.ACD.length > 0">
                  <small>
                    <a :href="project.ACD[0]" target="_blank"
                      ><span class="text-body-4 blue--text">ACD 1</span></a
                    >
                  </small>
                  <v-icon
                    v-if="
                      project.ACD.length > 1 ||
                      project.Facture.length > 0 ||
                      project.Contrat.length > 0
                    "
                    data-toggle="collapse"
                    :data-target="'#multiCollapseFile' + index"
                    aria-expanded="false"
                    :aria-controls="'multiCollapseFile' + index"
                  >
                    mdi-menu-down
                  </v-icon>
                </div>
                <div v-else-if="project.Facture.length > 0">
                  <small>
                    <a :href="project.Facture[0]" target="_blank"
                      ><span class="text-body-4 blue--text">Facture 1</span></a
                    >
                  </small>
                  <v-icon
                    v-if="
                      project.ACD.length > 0 ||
                      project.Facture.length > 1 ||
                      project.Contrat.length > 0
                    "
                    data-toggle="collapse"
                    :data-target="'#multiCollapseFile' + index"
                    aria-expanded="false"
                    :aria-controls="'multiCollapseFile' + index"
                  >
                    mdi-menu-down
                  </v-icon>
                </div>
                <div v-else-if="project.Contrat.length > 0">
                  <small>
                    <a :href="project.Contrat[0]" target="_blank"
                      ><span class="text-body-4 blue--text">Contrat 1</span></a
                    >
                  </small>
                  <v-icon
                    v-if="
                      project.ACD.length > 0 ||
                      project.Facture.length > 0 ||
                      project.Contrat.length > 1
                    "
                    data-toggle="collapse"
                    :data-target="'#multiCollapseFile' + index"
                    aria-expanded="false"
                    :aria-controls="'multiCollapseFile' + index"
                  >
                    mdi-menu-down
                  </v-icon>
                </div>
                <div
                  class="collapse multi-collapse"
                  :id="'multiCollapseFile' + index"
                >
                  <div v-for="(acd, index) in project.ACD">
                    <small>
                      <a :href="acd" target="_blank"
                        ><span class="text-body-4 blue--text"
                          >ACD {{ index + 1 }}</span
                        ></a
                      >
                    </small>
                  </div>
                  <div v-for="(Facture, index) in project.Facture">
                    <small>
                      <a :href="Facture" target="_blank"
                        ><span class="text-body-4 blue--text"
                          >Facture {{ index + 1 }}</span
                        ></a
                      >
                    </small>
                  </div>
                  <div v-for="(Contrat, index) in project.Contrat">
                    <small>
                      <a :href="Contrat" target="_blank"
                        ><span class="text-body-4 blue--text"
                          >Contrat {{ index + 1 }}</span
                        ></a
                      >
                    </small>
                  </div>
                </div>
              </div>
            </td>
            <td>{{ project.Echeance }}</td>
            <td>
              <div class="d-flex flex-column align-items-center btn-group">
                <div class="d-flex flex-row justify-content-center">
                  <small class="small-status" v-if="project.valide == '4'"
                    ><v-icon dense style="color: orange"
                      >mdi-close-circle</v-icon
                    ><b class="ml-2">annuler</b></small
                  >
                  <small class="small-status" v-if="project.valide == '3'"
                    ><v-icon dense style="color: orange"
                      >mdi-close-circle</v-icon
                    ><b class="ml-2">ACD expiré</b></small
                  >
                  <small class="small-status" v-if="project.valide == '2'"
                    ><v-icon dense style="color: red">mdi-close-circle</v-icon
                    ><b class="ml-2">refusée</b></small
                  >
                  <small class="small-status" v-if="project.valide == '1'"
                    ><v-icon dense style="color: #00e676"
                      >mdi-check-circle</v-icon
                    ><b class="ml-2">acceptée</b></small
                  >
                  <small class="small-status" v-if="project.valide == '0'"
                    ><v-icon dense style="color: gray"
                      >mdi-timer-refresh-outline</v-icon
                    ><b class="ml-2">en attente</b></small
                  >
                  <div class="d-flex flex-row">
                    <v-icon
                      class="dropdown-toggle"
                      data-toggle="collapse"
                      :data-target="'#collapseStatut' + index"
                      aria-expanded="false"
                      :aria-controls="'collapseStatut' + index"
                      size="20"
                      left
                    >
                      mdi-menu-down
                    </v-icon>
                  </div>
                </div>
                <div
                  class="collapse"
                  :id="'collapseStatut' + index"
                  style="border: 0px"
                >
                  <div class="d-flex flex-row mt-4 mt-lg-2">
                    <v-btn
                      @click="
                        ActionsDemandeDeCotation(
                          project.id,
                          'validerDemandeDeCotation/'
                        )
                      "
                      v-if="
                        project.valide != '1' &&
                        project.valide != '3' &&
                        project.valide != '4'
                      "
                      class="dropdown-project"
                      icon
                      small
                      plain
                    >
                      <v-icon color="green accent-3"> mdi-check-circle </v-icon>
                    </v-btn>
                    <v-btn
                      @click="
                        ActionsDemandeDeCotation(
                          project.id,
                          'refuserDemandeDeCotation/'
                        )
                      "
                      class="dropdown-project"
                      v-if="
                        project.valide != '2' &&
                        project.valide != '3' &&
                        project.valide != '4'
                      "
                      icon
                      small
                      plain
                    >
                      <v-icon color="red"> mdi-close-circle </v-icon>
                    </v-btn>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
        <noDataTable
          class="ma-4"
          v-if="projects.length == 0 && loadingTable == false"
        />
        <progressCircular class="ma-8" v-if="loadingTable == true" />
      </template>
    </datatable>
    <div
      class="d-flex flex-row justify-content-center justify-sm-start align-items-center"
    >
      <pagination
        v-if="projects.length > 0"
        :pagination="pagination"
        @selectNumberOfRecords="rechercheData"
        @first="getData(pagination.firstPage)"
        @last="getData(pagination.lastPage)"
        @prev="getData(pagination.prevPageUrl)"
        @current="getData(pagination.currentPage)"
        @next="getData(pagination.nextPageUrl)"
      >
      </pagination>
    </div>
  </div>
</template>

<script>
import "jquery/dist/jquery.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";

import Datatable from "../../components/dataTable/Datatable.vue";
import Pagination from "../../components/dataTable/Pagination.vue";
import noDataTable from "../../components/no-data-table.vue";
import progressCircular from "../../components/progress-circular.vue";
import Loading from "@/components/ui/loading-component.vue";
import axios from "axios";
export default {
  name: "toutDemandeDeCotation",
  components: {
    datatable: Datatable,
    pagination: Pagination,
    noDataTable,
    progressCircular,
    Loading,
  },
  data() {
    let sortOrders = {};
    let columns = [
      { width: "13vw", label: "Date d'ajout", name: "date", sortable: true },
      {
        width: "13vw",
        label: "Commercial",
        name: "Commerciale",
        sortable: true,
      },
      {
        width: "13vw",
        label: "Raison Sociale",
        name: "Raison",
        sortable: true,
      },
      { width: "13vw", label: "Energie", name: "typeDemande", sortable: true },
      { width: "13vw", label: "Compteur", name: "numCompteur", sortable: true },
      { width: "13vw", label: "Pièce jointe", sortable: true },
      {
        width: "13vw",
        label: "Début de contrat",
        name: "dateDebut",
        sortable: true,
      },
      { width: "13vw", label: "Statut", name: "status", sortable: true },
    ];
    columns.forEach((column) => {
      sortOrders[column.name] = -1;
    });
    return {
      selectedVendeur: null,
      vendeurs: [],
      dialogSheetFilter: false,
      loadingDialogGlobal: false,
      loadingGlobalValue: false,
      messageGlobal: "",
      errorGlobalValue: false,
      loadingTable: false,
      filterStatus: "tout",
      projects: [],
      columns: columns,
      rules: {
        requiredVendeur: (value) => !!value || "Veuillez sélectionner un vendeur",
      },
      sortKey: "date",
      sortOrders: sortOrders,
      perPage: ["15", "20", "30"],
      tableData: {
        statusFilter: "",
        draw: 0,
        length: 15,
        search: "",
        column: 0,
        dir: "desc",
      },
      pagination: {
        perPage: ["10", "15", "30"],
        firstPage: 1,
        lastPage: "",
        currentPage: "",
        total: "",
        lastPageUrl: "",
        nextPageUrl: "",
        prevPageUrl: "",
        from: "",
        to: "",
      },
    };
  },
  async created() {
    this.loadingTable = true;
    var whoIam = "";
    await this.whoIAm(this.getToken("token")).then((data) => {
      whoIam = data;
    });
    if (whoIam == "noToken") {
      localStorage.removeItem("vendeurName");
      localStorage.removeItem("typeUser");
      localStorage.removeItem("token");
      this.$router.push("/login");
    } else {
      localStorage.setItem("typeUser", whoIam);
    }
    await this.getData();
    this.loadingTable = false;
  },
  methods: {
    async createSheet(item) {
      if (this.$refs.formCreationExcel.validate()) {
        console.log(item);
        this.dialogSheetFilter = false;
        this.loadingDialogGlobal = true;
        this.loadingGlobalValue = true;
        await axios({
          url:
            process.env.VUE_APP_URL_API_CLIENT +
            "exportDemandeDeCotationBySelectedCompte/",
          method: "POST",
          responseType: "arraybuffer",
          data: {
            token: this.getToken("token"),
            id_compte: item,
          },
        })
          .then((res) => {
            const blob = new Blob([res.data], {
              type: "application/vnd.ms-excel",
            });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            const selected = this.vendeurs.find(
              (vendeur) => vendeur.id === this.selectedVendeur
            );
            link.setAttribute(
              "download",
              "demandeDecotation_" +
                selected.nom +
                "_" +
                selected.prenom +
                ".xls"
            );
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
            this.loadingDialogGlobal = false;
            this.loadingGlobalValue = false;
            this.selectedVendeur = null;
          })
          .catch((err) => {
            console.log(err);
            this.loadingDialogGlobal = true;
            this.loadingGlobalValue = false;
            this.errorGlobalValue = true;
            this.messageGlobal = err;
          });
      }
    },

    openDialogSheetFilter() {
      this.dialogSheetFilter = true;
      const formData = new FormData();
      formData.append("token", this.getToken("token"));

      axios({
        url: process.env.VUE_APP_URL_API_CLIENT + "getAllCompte/",
        method: "POST",
        data: formData,
      })
        .then((res) => {
          this.vendeurs = res.data.data.map((vendeur) => ({
            ...vendeur,
            nomComplet: `${vendeur.nom} ${vendeur.prenom}`,
          }));
          console.log(this.vendeurs);
        })
        .catch((error) => {
          console.error("Erreur lors de la récupération des données : ", error);
        });
    },
    filterByStatus() {
      var pageNumber = 0;
      if (this.filterStatus != "tout") {
        this.tableData.statusFilter = this.filterStatus;
        this.getData(0);
      } else {
        this.tableData.statusFilter = "";
        this.getData(0);
      }
    },
    rechercheData({ type, key }) {
      if (type == "search") {
        var pageNumber = 0;
        this.tableData.search = key;
      } else if (type == "nbrRecords") {
        this.tableData.length = key;
      }
      this.tableData.draw++;
      axios({
        url:
          process.env.VUE_APP_URL_API_CLIENT +
          "getToutDemandeDeCotationServerSide/",
        method: "POST",
        data: {
          dataTable: this.tableData,
          pageNumber: pageNumber,
          token: this.getToken("token"),
        },
      })
        .then((response) => {
          if (response.data.result == true) {
            let data = response.data.data;
            if (this.tableData.draw == data.draw) {
              this.projects = data.data.data;
              this.configPagination(data.data);
            }
          } else {
            alert("permission refusée");
            localStorage.removeItem("vendeurName");
            localStorage.removeItem("typeUser");
            localStorage.removeItem("token");
            this.$router.push("/login");
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    async getData(pageNumber = 0) {
      this.tableData.draw++;
      await axios({
        url:
          process.env.VUE_APP_URL_API_CLIENT +
          "getToutDemandeDeCotationServerSide/",
        method: "POST",
        data: {
          dataTable: this.tableData,
          pageNumber: pageNumber,
          token: this.getToken("token"),
        },
      })
        .then((response) => {
          if (response.data.result == true) {
            let data = response.data.data;
            if (this.tableData.draw == data.draw) {
              this.projects = data.data.data;
              this.configPagination(data.data);
            }
          } else {
            alert("permission refusée");
            localStorage.removeItem("vendeurName");
            localStorage.removeItem("typeUser");
            localStorage.removeItem("token");
            this.$router.push("/login");
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    ActionsDemandeDeCotation(id, urlAPI) {
      this.dialog = true;
      this.loading = true;
      this.error = false;
      let self = this;
      var formData = new FormData();
      formData.append("id", id);
      formData.append("token", this.getToken("token"));
      axios({
        url: process.env.VUE_APP_URL_API_CLIENT + urlAPI,
        method: "POST",
        data: formData,
      })
        .then((res) => {
          if (res.data.result === "permission") {
            localStorage.removeItem("vendeurName");
            localStorage.removeItem("typeUser");
            localStorage.removeItem("token");
            this.$router.push("/login");
          } else if (res.data.result == true) {
            this.getData(this.pagination.currentPage);
          } else if (res.data.result == false) {
            this.getData(this.pagination.currentPage);
            alert("la demande n'existe pas !");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    spliteList(list) {
      return list.split(",");
    },
    spliteListSlice(list) {
      return list.split(",").slice(1);
    },
    configPagination(data) {
      this.pagination.lastPage = data.last_page;
      this.pagination.currentPage = data.current_page;
      this.pagination.total = data.total;
      this.pagination.lastPageUrl = data.last_page_url;
      this.pagination.nextPageUrl = data.next_page_url;
      this.pagination.prevPageUrl = data.prev_page_url;
      this.pagination.from = data.from;
      this.pagination.to = data.to;
    },
    sortBy(key) {
      this.sortKey = key;
      this.sortOrders[key] = this.sortOrders[key] * -1;
      this.tableData.column = this.getIndex(this.columns, "name", key);
      this.tableData.dir = this.sortOrders[key] === 1 ? "asc" : "desc";
      this.getData();
    },
    getIndex(array, key, value) {
      return array.findIndex((i) => i[key] == value);
    },
  },
};
</script>
<style>
#table-validation-cotation th {
  background-color: #000;
  color: white;
  font-weight: bold;
}
@media only screen and (max-width: 400px) {
  .container-div {
    width: 125px !important;
    padding: 0px;
    margin: 0;
  }
}
@media only screen and (max-width: 760px),
  (min-device-width: 768px) and (max-device-width: 1024px) {
  @import "../../components/dataTable/style.css";
  #table-validation-cotation td:nth-of-type(1):before {
    content: "Date d'ajout";
  }
  #table-validation-cotation td:nth-of-type(2):before {
    content: "Commercial";
  }
  #table-validation-cotation td:nth-of-type(3):before {
    content: "Raison Sociale";
  }
  #table-validation-cotation td:nth-of-type(4):before {
    content: "Energie";
  }
  #table-validation-cotation td:nth-of-type(5):before {
    content: "Compteur";
  }
  #table- td:nth-of-type(6):before {
    content: "Pièce jointe";
  }
  #table-validation-cotation td:nth-of-type(7):before {
    content: "Début de contrat";
  }
  #table-validation-cotation td:nth-of-type(8):before {
    content: "Statut";
  }
}
.small-status {
  width: max-content;
}

.td-table,
.td-valeur,
.td-statut {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>
