<template>
<ul class="mt-4" v-if="this.isVisible" v-cloak>
                  <li v-for="(files,index) in fileCopy" class="text-sm p-1">
                    <small> {{files.name}}</small>
                    <v-btn
                      class="mx-2"
                      icon
                      color="error"
                      @click="remove(index)"
                      title="Remove file"
                    >
                      <v-icon dark> mdi-delete-forever </v-icon>
                    </v-btn>
                  </li>
                </ul>
</template>
<script>
export default{
    name:"listFile",
    props:["isVisible","file"],
    data(){
        return {
            fileCopy : this.file
        }
    },
    

    watch :{
        file:function(newVal,oldVal){
          this.fileCopy = this.file
        }
    },

    methods:{
      remove(index){
        this.fileCopy.splice(index,1)
        this.$emit("remove",this.fileCopy)
      }
    }

}
</script>