<template>
    <div>
        <v-row v-if="dialog">
            <loadingComponent @closeDialog='dialog = $event' :dialogProps="dialog" :messageDialogeProps="messageDialogeError" :loadingProps="loading" :errorProps="error" />
        </v-row>
            <!--<TestView class="text-right pa-2 marginBtnPresentation" :parametreCompteur="parametreCompteur" :donnerDesFournisseur="Propositions" :nbrMonthsEnedis="enedisData['mesuresInfo'][0]['nbrMonth']" 
            :dataEnedis="enedisData" :numCompteur="numCompteur" :turpeData="turpeData"
            :indexes="selectedData" :typeCompteur="typeCompteur" :fournisseurActuelle="fournisseurActuelle" :donneDeCotation="donneDeCotation"/>-->
            <!-- v-for="(item,i) in 5" :key="i" -->
            <presentation :donnerCompteur="donnerCompteur" :parametreCompteur="parametreCompteur" :donnerDesFournisseur="Propositions" :nbrMonthsEnedis="enedisData['mesuresInfo'][0]['nbrMonth']" 
            :dataEnedis="enedisData" :numCompteur="numCompteur" :turpeData="turpeData"
            :indexes="selectedData" :typeCompteur="typeCompteur" :fournisseurActuelle="fournisseurActuelle" :societe="SocieteData" :donneDeCotation="donneDeCotation"/>
            <v-expansion-panels :readonly="readonlyExpansionPanels" style="z-index:1">
              <v-list-item
                    style="background: rgba(255, 255, 255, 0.1);"
                    class="pa-2 propositions-container my-4"
                    v-for="(company, index) in Propositions"
                    :key="company.name"
                >
                <v-expansion-panel style="z-index:1">
                    <v-expansion-panel-header hide-actions class="pa-0" style="z-index:1">
                        
                        <v-row outlined no-gutters class="header" style="background: #ffffff;" v-if="('message' in company)==true">
                            <v-col>
                                <p style="color: red;"> {{company.message}}</p>
                            </v-col>
                            <div>
                                <v-img
                                    class="ml-10"
                                    overflow="hiden"
                                    max-height="80px"
                                    width="200px"
                                    contain
                                    :src="getImageLogo(company.logo)"
                                >
                                </v-img>
                            </div>
                        </v-row>
                        <v-row outlined no-gutters class="header" style="background: #ffffff;" v-else>
                        
                        <!--<v-col cols="12" sm="12" md="12" lg="12" xl="12"  v-if="company.fournisseur == 'Primeo'">
                            <p style="color: red;"> Attention offre valable uniquement pour renouvellement primeo</p>
                        </v-col>-->
                        <v-col class="ml-8 mt-2" cols="12" sm="12" md="12" lg="12" xl="12"  v-if="company.fournisseur == 'Gazel Energie'">
                            <p style="color: red;"> Attention, avant l'edition du contrat une verification du BILAN 2021/2022 est necessaire</p>
                        </v-col>
                        
                        <v-row class="mb-2 mt-2">
                            <v-checkbox style="position:absolute;top:0;left:0;z-index:3" v-if="fournisseurActuelle == true && index == 0"
                                class="ma-0 pa-0"
                                input-value="true"
                                :disabled="true"
                                ></v-checkbox>
                            <v-checkbox style="position:absolute;top:0;left:0;z-index:3" v-else
                                class="ma-0 pa-0"
                                v-model="selectedData"
                                @click.capture.stop
                                :value="index"
                                :disabled="(selectedData.length > 4 && selectedData.indexOf(index) === -1) || (selectedData.length > 3 && selectedData.indexOf(index) === -1 && fournisseurActuelle == true)"
                                ></v-checkbox>
                            <div>
                                <v-img v-if="company.fournisseur == 'OHM'"
                                    style="border-radius:5px"
                                    overflow="hiden"
                                    max-height="80px"
                                    width="200px"
                                    class="ml-10"
                                    contain
                                    :src="getImageLogo(company.logo)"
                                >
                                </v-img>
                                <v-img v-else
                                    style="border-radius:5px" 
                                    class="ml-10"
                                    overflow="hiden"
                                    max-height="80px"
                                    width="200px"
                                    contain
                                    :src="getImageLogo(company.logo)"
                                >
                                </v-img>
                            </div>
                            <div class="d-flex flex-row mr-4">
                                <div class="d-flex flex-row align-center" v-if="company.typeFournisseur != 'AuFrn' && company.typeFournisseur != 'Actuelle'">
                                    <p v-if="company.type" class="grey--text font-weight-light  mx-2 mb-0">Type d'offre : <span style="color:#000" class="font-weight-bold">{{ company.type }}</span></p>
                                    <p class="grey--text font-weight-light  mx-2 mb-0">Début de contrat : <span style="color:#000" class="font-weight-bold">{{ company.dateDebut }}</span></p>
                                    <p class="grey--text font-weight-light  mx-2 mb-0">Fin de contrat :<span style="color:#000" class="font-weight-bold ml-2">{{ company.dateFin }}</span></p>
                                </div>
                                <div class="d-flex flex-row align-center" v-else>
                                    <p class="grey--text font-weight-light  mx-2 mb-0">Type d'offre : <span style="color:#000" class="font-weight-bold">{{ company.type }}</span></p>
                                    <p class="grey--text font-weight-light  mx-2 mb-0">Durée de contrat : <span style="color:#000" class="font-weight-bold">{{ company.DUREE }} mois</span></p>
                                </div>
                                <div class="d-flex flex-row align-center ">
                                    <p class="grey--text font-weight-light  mb-0 mx-2">Budget annuel :</p>
                                    <p class="grey--text font-weight-light  mb-0 mx-2 ">
                                        <span style="color:#000" class="font-weight-bold">{{ ""+company.budget.toLocaleString()}} €</span>
                                    </p>
                                </div>
                            </div>
                        </v-row>
                            <div v-if="(company.fournisseur != 'Enovos' && company.fournisseur != 'Synelva' && 
                            company.fournisseur != 'WEKIWI' && company.fournisseur != 'Gazel Energie') || (company.fournisseur == 'OHM' &&  company.type == 'Prix Fixe')" style="height:fit-content" >
                                    <engiePro v-if="company.fournisseur == 'engie'  || company.fournisseur == 'Engie'" @readonlyExpand="readonlyExpansionPanels = $event" :donner="company" :optionTarifaire="optionTarifaire" :numCompteur="numCompteur" :societe="SocieteData" :donnerEnedis="enedisData"  />
                                    <div v-if="company.fournisseur == 'Ekwateur'">
                                        <EkwateurContrat @readonlyExpand="readonlyExpansionPanels = $event" :donner="company" :listCompteur="listCompteur" :optionTarifaire="optionTarifaire" :numCompteur="numCompteur" :societe="SocieteData" :donnerEnedis="enedisData"  />
                                    </div>
                                    <!--<div v-if="company.fournisseur == 'OHM' &&  company.type == 'Prix Fixe'">
                                        <ohmAvecArenh :optionTarifaire="optionTarifaire" :donner="company" :numCompteur="numCompteur" 
                                        :societe="SocieteData" :donnerEnedis="enedisData" :parametreCompteur="parametreCompteur"/>
                                    </div>-->
                                    <!--<v-col v-if="company.fournisseur == 'Gazel Energie'" cols="6" sm="6" md="6" lg="6" xl="6">
                                        <gazelContrat :donner="company" :numCompteur="numCompteur" :societe="SocieteData" :offre="company.type" :donnerEnedis="enedisData" />
                                    </v-col>-->
                                    <div v-if="company.fournisseur == 'total energie' && company.type != 'HORIZON'">
                                        <Totale :donner="company" :numCompteur="numCompteur" :societe="SocieteData" :donnerEnedis="enedisData" />
                                    </div>
                                
                                    <div v-if="company.fournisseur == 'total energie' && company.type == 'HORIZON'">
                                        <TotalHorizon :donner="company" :numCompteur="numCompteur" :societe="SocieteData" :donnerEnedis="enedisData" />
                                    </div>
                                    <div v-if="company.fournisseur == 'EDS' && company.typeFournisseur != 'AuFrn' && company.typeFournisseur != 'Actuelle'">
                                        <edsContrat :optionTarifaire="optionTarifaire" :donner="company" :numCompteur="numCompteur" :societe="SocieteData" :donnerEnedis="enedisData" />
                                    </div>
                                    
                                    <!--<v-col v-if="company.fournisseur == 'vattenfall'" cols="6" sm="6" md="6" lg="6" xl="6">
                                        <vattenfallContrat :donner="company" :numCompteur="numCompteur" :societe="SocieteData" :donnerEnedis="enedisData" />
                                    </v-col>-->
                                
                                    <v-col v-if="company.fournisseur == 'Primeo' && company.typeFournisseur == 'normal'" cols="5" sm="5" md="7" lg="5" xl="5">
                                        <!--<premioContrat  :codePremio="codePremio" :optionTarifaire="optionTarifaire" :donner="company" :numCompteur="numCompteur" :societe="SocieteData" :donnerEnedis="enedisData" />-->
                                        <primeoMulti  :codePremio="listecodePremio" :optionTarifaire="optionTarifaire" :donner="company" :numCompteur="numCompteur" :societe="SocieteData" :donnerEnedis="enedisData" />
                                    </v-col>
                                    <v-col v-if="company.fournisseur == 'vattenfall' && company.type=='prix fixe'" cols="6" sm="6" md="6" lg="6" xl="6">
                                        <vattenfallContrat :donner="company" :numCompteur="numCompteur" :societe="SocieteData" :donnerEnedis="enedisData" />
                                    </v-col>
                                    <v-col v-if="company.fournisseur == 'LLUM'" cols="6" sm="6" md="6" lg="6" xl="6">
                                        <LLUMContratElec :donner="company" :optionTarifaire="optionTarifaire" :numCompteur="numCompteur" :societe="SocieteData" :donnerEnedis="enedisData"  />
                                    </v-col>
                                    <v-col v-if="company.fournisseur == 'LLUMC5'" cols="6" sm="6" md="6" lg="6" xl="6">
                                        <LLUMContratElecC5 @readonlyExpand="readonlyExpansionPanels = $event" :donner="company" :optionTarifaire="optionTarifaire" :numCompteur="numCompteur" :societe="SocieteData" :donnerEnedis="enedisData"  />
                                    </v-col>
                                    <v-col v-if="company.fournisseur == 'Elecocite' && company.typeFournisseur == 'normal' && company.type == 'Variable'" cols="6" sm="6" md="6" lg="6" xl="6">
                                        <ElecociteContrat @readonlyExpand="readonlyExpansionPanels = $event" :donner="company" :optionTarifaire="optionTarifaire" :numCompteur="numCompteur" :societe="SocieteData" :donnerEnedis="enedisData"  />
                                    </v-col>
                            </div>
                                
                        <v-col style="background: #ffffff;" cols="12" sm="12"  md="12" lg="12"  xl="12" class="header d-flex flex-column"
                            :id="index" v-if="index != panel">
                            <v-col
                                cols="12"
                                sm="12"
                                md="12"
                                lg="12"
                                xl="12"
                                class="d-flex flex-column pt-0 px-0 pb-0"
                            >
                                <div class="px-4">
                                    <v-divider class="mb-3"></v-divider>
                                </div>
                                <v-card  class="d-flex flex-row justify-space-between rounded-0 bo  pl-4 pr-3 pb-4 pt-4" style="box-shadow:0px 0px 0px 0px">
                                <v-col class="pa-0" cols="10" sm="4" md="3" lg="2" xl="2" align="center" justify="center">
                                    <p class="d-flex flex-column text-body-1  ma-0" >
                                        <span class="text-body-1 grey--text font-weight-light responsiveSpan"
                                        >Prix du KWh</span
                                        ><span class="text-body-1  font-weight-bold" style="color:#000">{{ ""+company.budgetKWH.toLocaleString()}} €</span>
                                    </p>
                                </v-col>
                                <v-col class="pa-0" cols="10" sm="4" md="3" lg="2" xl="2" align="center" justify="center">
                                    <p class="d-flex flex-column text-body-1  ma-0" >
                                        <span class="text-body-1 font-weight-light responsiveSpan  grey--text">CAPA</span>
                                        <span class="text-body-1 font-weight-bold" style="color:#000">{{ company.budgetCapa==0 ? "Inclus" :  ""+company.budgetCapa.toLocaleString()+"€"}}</span>
                                    </p>
                                </v-col>
                                <v-col class="pa-0" cols="10" sm="4" md="3" lg="2" xl="2" align="center" justify="center">
                                    <p class="d-flex flex-column text-body-1  ma-0" >
                                        <span class="text-body-1 grey--text font-weight-light responsiveSpan">TURPE</span>
                                        <span v-if="company.TURPE == 'inclus'" class="text-body-1 font-weight-bold" style="color:#000">Inclus</span>
                                        <span v-else class="text-body-1 font-weight-bold" style="color:#000">{{ ""+company.TURPE.toLocaleString() }} €</span>
                                    </p>
                                </v-col>
                                <v-col class="pa-0" cols="10" sm="4" md="3" lg="2" xl="2" align="center" justify="center">
                                    <p class="d-flex flex-column text-body-1  ma-0" >
                                        <span class="text-body-1 grey--text font-weight-light responsiveSpan">TAXE</span>
                                        <span class="text-body-1 font-weight-bold" style="color:#000">{{ ""+company.budgetTaxe.toLocaleString() }} €</span>
                                    </p>
                                </v-col>
                                </v-card>
                            </v-col>
                        </v-col>
                        </v-row>
                    </v-expansion-panel-header>
                <v-expansion-panel-content style="z-index:1;background-color:rgb(245,245,245)" class="pa-0 header" v-if="('message' in company)==false">
                    <v-row justify="space-between" outlined no-gutters class="pa-0 pb-4 header">
                        <!-- Prix du KWh -->
                        
                        <v-col align="center" cols="10" sm="4" md="3" lg="2" xl="2" class="pl-4">
                                <div class="d-flex flex-column " v-if="typeCompteur == 'C3'">
                                    <span class="text-body-1 grey--text font-weight-light   mb-1">POINTE </span>
                                    <span style="color:#000" class="font-weight-bold">{{ ""+company.unitairepointe.toLocaleString() }} €/MWh</span>
                                </div>
                                <div class="d-flex flex-column " v-if="typeCompteur == 'C5HP'">
                                    <span class="text-body-1 grey--text font-weight-light   mb-1">HP </span>
                                    <span style="color:#000" class="font-weight-bold">{{ ""+company.unitairehp.toLocaleString() }} €/MWh</span>
                                </div>
                                <div class="d-flex flex-column " v-if="typeCompteur == 'C5HP'">
                                    <span class="text-body-1 grey--text font-weight-light   mb-1">HC </span>
                                    <span style="color:#000" class="font-weight-bold">{{ ""+company.unitairehc.toLocaleString()}} €/MWh</span>
                                </div>
                                <div class="d-flex flex-column " v-if="typeCompteur == 'C5BASE'">
                                    <span class="text-body-1 grey--text font-weight-light   mb-1">BASE </span>
                                    <span style="color:#000" class="font-weight-bold">{{ ""+company.unitaire.toLocaleString() }} €/MWh</span>
                                </div>
                                <div class="d-flex flex-column " v-if="typeCompteur == 'C3' || typeCompteur == 'C4'">
                                    <span class="text-body-1 grey--text font-weight-light   mb-1">HPH </span>
                                    <span style="color:#000" class="font-weight-bold">{{ ""+company.unitairehph.toLocaleString() }} €/MWh</span>
                                </div>
                                <div class="d-flex flex-column " v-if="typeCompteur == 'C3' || typeCompteur == 'C4'">
                                    <span class="text-body-1 grey--text font-weight-light   mb-1">HCH </span>
                                    <span style="color:#000" class="font-weight-bold">{{ ""+company.unitairehch.toLocaleString() }} €/MWh</span>
                                </div>
                                <div class="d-flex flex-column " v-if="typeCompteur == 'C3' || typeCompteur == 'C4'">
                                    <span class="text-body-1 grey--text font-weight-light   mb-1">HPE </span>
                                    <span style="color:#000" class="font-weight-bold">{{ ""+company.unitairehpe.toLocaleString() }} €/MWh</span>
                                </div>
                                <div class="d-flex flex-column " v-if="typeCompteur == 'C3' || typeCompteur == 'C4'">
                                    <span class="text-body-1 grey--text font-weight-light   mb-1">HCE </span>
                                    <span style="color:#000" class="font-weight-bold">{{ ""+company.unitairehce.toLocaleString() }} €/MWh</span>
                                </div>
                                <div class="d-flex flex-column ">
                                    <span class="text-body-1 grey--text font-weight-light   mb-1">CEE </span>
                                    <span style="color:#000" class="font-weight-bold" v-if="company.cee == 'NON SOUMIS'">NON SOUMIS</span>
                                    <span style="color:#000" class="font-weight-bold" v-else>{{ ""+company.cee.toLocaleString() }}€/MWh</span>
                                </div>
                                <div class="d-flex flex-column ">
                                    <span class="text-body-1 grey--text font-weight-light   mb-1">Abonnement mensuel </span>
                                    <span style="color:#000" class="font-weight-bold">{{ ""+company.mensuel.toLocaleString() }} €</span>
                                </div>
                        </v-col>

                        <!-- CAPA -->
                        <v-col align="center" class="pt-0 d-flex flex-column"
                                cols="10" sm="4" md="3" lg="2" xl="2">
                                <div class="mb-1 d-flex flex-column " v-if="typeCompteur == 'C3'">
                                    <span class="text-body-1 grey--text font-weight-light   mb-1">POINTE </span>
                                    <span style="color:#000" class="font-weight-bold" v-if="company.capa_pointe == 'inclus'">Inclus</span>
                                    <span style="color:#000" class="font-weight-bold" v-else>{{company.budgetCapa==0 ? "Inclus" : ""+company.capa_pointe.toLocaleString()+"€/MWh"}} </span>
                                </div>
                                <div class="mb-1 d-flex flex-column " v-if="typeCompteur == 'C5HP'">
                                    <span class="text-body-1 grey--text font-weight-light   mb-1">HP</span>
                                    <span style="color:#000" class="font-weight-bold" v-if="company.capa_hp == 'inclus'">Inclus</span>
                                    <span style="color:#000" class="font-weight-bold" v-else
                                        >{{ company.budgetCapa==0 ? "Inclus" : ""+company.capa_hp.toLocaleString() +"€/MWh" }}</span
                                    >
                                </div>
                                <div class="mb-1 d-flex flex-column " v-if="typeCompteur == 'C5HP'">
                                    <span class="text-body-1 grey--text font-weight-light   mb-1">HC </span>
                                    <span style="color:#000" class="font-weight-bold" v-if="company.capa_hc == 'inclus'">Inclus</span>
                                    <span style="color:#000" class="font-weight-bold" v-else
                                        >{{ company.budgetCapa==0 ? "Inclus" : ""+company.capa_hc.toLocaleString()+ "€/MWh"}}</span
                                    >
                                </div>
                                <div class="mb-1 d-flex flex-column " v-if="typeCompteur == 'C5BASE'">
                                    <span class="text-body-1 grey--text font-weight-light   mb-1">BASE </span>
                                    <span style="color:#000" class="font-weight-bold" v-if="company.capa_base == 'inclus'">Inclus</span>
                                    <span style="color:#000" class="font-weight-bold" v-else
                                        >{{ company.budgetCapa==0 ? "Inclus" : ""+company.capa_base.toLocaleString()+"€/MWh"}}</span>
                                </div>
                                <div class="mb-1 d-flex flex-column " v-if="typeCompteur == 'C3' || typeCompteur == 'C4'">
                                    <span class="text-body-1 grey--text font-weight-light   mb-1">HPH </span>
                                    <span style="color:#000" class="font-weight-bold" v-if="company.capa_hph == 'inclus'">Inclus</span>
                                    <span style="color:#000" class="font-weight-bold" v-else>{{company.budgetCapa==0 ? "Inclus" : ""+company.capa_hph.toLocaleString() +"€/MWh"}}</span>
                            
                                </div>
                                <div class="mb-1 d-flex flex-column " v-if="typeCompteur == 'C3' || typeCompteur == 'C4'">
                                    <span class="text-body-1 grey--text font-weight-light   mb-1">HCH </span>
                                    <span style="color:#000" class="font-weight-bold" v-if="company.capa_hch == 'inclus'">Inclus</span>
                                    <span style="color:#000" class="font-weight-bold" v-else>{{company.budgetCapa==0 ? "Inclus" : ""+company.capa_hch.toLocaleString() +"€/MWh"}}</span>
                                </div>
                                <div class="mb-1 d-flex flex-column " v-if="typeCompteur == 'C3' || typeCompteur == 'C4'">
                                    <span class="text-body-1 grey--text font-weight-light   mb-1">HPE </span>
                                    <span style="color:#000" class="font-weight-bold" v-if="company.capa_hpe == 'inclus'">Inclus</span>
                                    <span style="color:#000" class="font-weight-bold" v-else>{{ company.budgetCapa==0 ? "Inclus" : ""+company.capa_hpe.toLocaleString() +"€/MWh"}}</span>
                                </div>
                                <div class="mb-1 d-flex flex-column " v-if="typeCompteur == 'C3' || typeCompteur == 'C4'">
                                    <span class="text-body-1 grey--text font-weight-light   mb-1">HCE </span>
                                    <span style="color:#000" class="font-weight-bold" v-if="company.capa_hce == 'inclus'">Inclus</span>
                                    <span style="color:#000" class="font-weight-bold" v-else>{{company.budgetCapa==0 ? "Inclus" : ""+company.capa_hce.toLocaleString()+"€/MWh"}}</span>
                                </div>
                        </v-col>

                        <!-- TURP -->
                        <v-col
                        cols="10" sm="4" md="3" lg="2" xl="2"
                        align="center"
                        class="pt-0 d-flex flex-column"
                        >
                                    <div>
                                        <p class="mb-1 d-flex flex-column">
                                        <span class="text-body-1 grey--text font-weight-light   mb-1">Énergie
                                        </span>
                                        <span style="color:#000" class="font-weight-bold" v-if="company.TURPE == 'inclus'">Inclus</span>
                                        <span style="color:#000" class="font-weight-bold" v-else>{{ ""+turpeData.energieTurp.toLocaleString() }} €</span>
                                        </p>
                                    </div>
                                    <div>
                                        <p class="mb-1 d-flex flex-column">
                                        <span class="text-body-1 grey--text font-weight-light   mb-1"
                                            >C.A de gestion
                                        </span>
                                        <span style="color:#000" class="font-weight-bold" v-if="company.CAG == 'inclus'">Inclus</span>
                                        <span style="color:#000" class="font-weight-bold" v-else>{{ ""+company.CAG.toLocaleString() }} €</span>
                                        </p>
                                    </div>
                                    <div>
                                        <p class="mb-1 d-flex flex-column">
                                        <span class="text-body-1 grey--text font-weight-light   mb-1"
                                            >C.A de comptage
                                        </span>
                                        <span style="color:#000" class="font-weight-bold" v-if="company.CACC == 'inclus'">Inclus</span>
                                        <span style="color:#000" class="font-weight-bold" v-else>{{ ""+company.CACC.toLocaleString() }} €</span>
                                        </p>
                                    </div>
                                    <div>
                                        <p class="mb-1 d-flex flex-column">
                                        <span class="text-body-1 grey--text font-weight-light   mb-1">Puissance souscrite
                                        </span>
                                        <span style="color:#000" class="font-weight-bold" v-if="company.TURPE == 'inclus'">Inclus</span>
                                        <span style="color:#000" class="font-weight-bold" v-else>{{ ""+turpeData.PSTurpe.toLocaleString() }} €</span>
                                        </p>
                                    </div>
                        </v-col>

                        <!-- TAXE -->
                        <v-col
                        cols="10" sm="4" md="3" lg="2" xl="2"
                        align="center"
                        class="pt-0 d-flex flex-column"
                        >
                                    <!-- <div>
                                        <p class="mb-1 d-flex flex-column ">
                                        <span class="text-body-1 grey--text font-weight-light   mb-1"
                                            >TCFE
                                        </span>
                                        <span style="color:#000" class="font-weight-bold"
                                            >{{
                                            ""+company.TAXE.toLocaleString()
                                            }}
                                            €</span
                                        >
                                        </p>
                                    </div> -->
                                    <div>
                                        <p class="mb-1 d-flex flex-column ">
                                        <span class="text-body-1 grey--text font-weight-light   mb-1">CTA </span>
                                        <span style="color:#000" class="font-weight-bold">{{ ""+company.CTA.toLocaleString() }} €/an</span>
                                        </p>
                                        <p class="mb-1 d-flex flex-column ">
                                        <span class="text-body-1 grey--text font-weight-light   mb-1">CSPE </span>
                                        <span style="color:#000" class="font-weight-bold">{{ ""+company.CSPE.toLocaleString() }} €/MWh</span>
                                        </p>
                                    </div>
                                    <!-- <div>
                                        <p class="mb-1 d-flex flex-column ">
                                        <span class="text-body-1 grey--text font-weight-light   mb-1"
                                            >Locales communales
                                        </span>
                                        <span style="color:#000" class="font-weight-bold"
                                            >{{ ""+company.taxe_communal.toLocaleString() }} €
                                        </span>
                                        </p>
                                    </div> -->
                                    <!-- <div>
                                        <p class="mb-1 d-flex flex-column ">
                                        <span class="text-body-1 grey--text font-weight-light   mb-1"
                                            >Locales départementales
                                        </span>
                                        <span style="color:#000" class="font-weight-bold"
                                            >{{
                                            ""+company.taxe_departemantale.toLocaleString()
                                            }}
                                            €</span
                                        >
                                        </p>
                                    </div> -->
                        </v-col>
                    </v-row>
                    
                </v-expansion-panel-content>
                </v-expansion-panel>
              </v-list-item>
            </v-expansion-panels>
    </div>
</template>

<script>
import ElecociteContrat from '../../views/edition-contrat/Elecocite.vue'
import TestView from '../../views/TestView'
import presentation from '../../views/presentation/presentations.vue'
import premioContrat from '../../views/edition-contrat/primeoEnergie.vue'
import engiePro from '../../views/edition-contrat/engiePro.vue'
import gazelContrat from '../../views/edition-contrat/gazelContrat.vue'
import Totale from '../../views/edition-contrat/tataleContrat.vue'
import edsContrat from '../../views/edition-contrat/EDS.vue'
import TotalHorizon from '../../views/edition-contrat/totalHorizon.vue'
import vattenfallContrat from '../../views/edition-contrat/Vattenfall.vue'
import EkwateurContrat from '../../views/edition-contrat/Ekwateur.vue'
import LLUMContratElec from '../../views/edition-contrat/LLUM_ELEC.vue'
import loadingComponent from '../ui/loading-component.vue'
import primeoMulti from '../../views/edition-contrat/primeoMulti.vue'
import ohmAvecArenh from '../../views/edition-contrat/ohmAvecArenh.vue'
import LLUMContratElecC5 from '../../views/edition-contrat/LLUMContratElecC5.vue'
import axios from 'axios'
export default {
    name: 'ListDesPropostions',
    components:{loadingComponent},
    props: {
        optionTarifaire: '',
        parametreCompteur: null,
        Propositions: [],
        SocieteData:[],
        enedisData: [],
        numCompteur: null,
        turpeData: [],
        taxeData: [],
        typeCompteur: null,
        panel: [],
        fournisseurActuelle: null,
        selected: [],
        listCompteur: [],
        donneDeCotation: [],
        donnerCompteur: [],
    },
    components: {
        TestView,
        presentation,
        premioContrat,
        engiePro,
        gazelContrat,
        Totale,
        edsContrat,
        TotalHorizon,
        vattenfallContrat,
        EkwateurContrat,
        LLUMContratElec,
        primeoMulti,
        ohmAvecArenh,
        LLUMContratElecC5,
        ElecociteContrat
    },
    data() {
        return {
            selectedData:this.selected,
            panelData: this.panel,
            readonlyExpansionPanels:false,
            dialog: false,
            loading: false,
            error: false,
            codePremio: '',
            listecodePremio: [],
            messageDialogeError:'',
            messageDialogeSucces:'',
        }
    },
    methods:{
        async validerOffreMultiPrimeo(offre, demandeur, firstName,  lastName,  phone,  email){
            var errorValide = 0;
            var msg = ""
            this.dialog = true
            this.loading = true
            this.error = false
            this.messageDialogeError = ''
            this.messageDialogeSucces = ''
            var years = ['2023', '2024', '2025', '2026']
            for(var i=0; i<years.length; i++){
                if(years[i] in offre){
                    var formData = new FormData()
                    formData.append('offerId', offre[years[i]].offerId)
                    formData.append('transactionId', offre[years[i]].transactionId)
                    formData.append('demandeur', demandeur)
                    formData.append('firstName', firstName)
                    formData.append('lastName', lastName)
                    formData.append('phone', phone)
                    formData.append('email', email)
                    formData.append('numCompteur', this.numCompteur)
                    formData.append('raison', this.SocieteData.Raison)
                    // cout capa
                    //upload pdf file to php server
                    await axios({
                        url: process.env.VUE_APP_URL_API_CLIENT + 'ValiderOffrePrimeoMulti/',
                        method: 'POST',
                        data: formData,
                        
                    }).then((res) => {
                        if(res.data.valider == true){
                            this.listecodePremio=res.data.oppid
                            errorValide = 0
                        }else{
                            errorValide = 1
                            msg = res.data.message
                        }
                    }).catch((err) => {
                            errorValide = 2
                    })
                    this.loading = false
                    this.error = false
                    this.readonlyExpansionPanels = false;
                    if(errorValide==0){
                        this.messageDialogeSucces = "l'offre a été validée avec succès"
                        alert(this.messageDialogeSucces)
                    }else if(errorValide==1){
                        this.messageDialogeError = msg
                        alert(this.messageDialogeError)
                    }else if(errorValide==2){
                        this.messageDialogeError = "une erreur s'est produite lors du validation d'offre"
                        alert(this.messageDialogeError)
                    }
                }
            }
            
        },
        validOffre(offerId, transactionId, demandeur, firstName,  lastName,  phone,  email){
            this.dialog = true
            this.loading = true
            this.error = false
            this.messageDialogeError = ''
            this.messageDialogeSucces = ''
            var formData = new FormData()
            formData.append('offerId', offerId)
            formData.append('transactionId', transactionId)
            formData.append('demandeur', demandeur)
            formData.append('firstName', firstName)
            formData.append('lastName', lastName)
            formData.append('phone', phone)
            formData.append('email', email)
            formData.append('numCompteur', this.numCompteur)
            formData.append('raison', this.SocieteData.Raison)
            // cout capa
            //upload pdf file to php server
            axios({
                url: process.env.VUE_APP_URL_API_CLIENT + 'ValiderOffrePrimeo/',
                method: 'POST',
                data: formData,
                
            }).then((res) => {
                if(res.data.valider == true){
                    this.codePremio = res.data.oppid
                    this.loading = false
                    this.error = false
                    this.messageDialogeSucces = "l'offre a été validée avec succès"
                    
                }else{
                    this.loading = false
                    this.error = true
                    this.messageDialogeError = res.data.message
                }
            }).catch((err) => {
                    this.loading = false
                    this.error = true
                    this.messageDialogeError = "une erreur s'est produite lors du validation d'offre"
            })
        },
        getImageLogo(logo) {
            return require('@/assets/logoParticulier/' + logo)
        },
    },
}
</script>
<style scoped>
::v-deep .v-expansion-panel-content__wrap{
    padding:0px !important;
}
@media only screen and (max-width:500px){
     .propositions-container{
        display:flex;
        flex-direction:column;
        height:min-content;
        padding:15px;
        margin-right:0px !important;
    }
}

@media only screen and (max-width:600px){

    .marginBtnPresentation{
        padding-right:0px !important;
        padding-left:0px !important;
     }
}

@media only screen and (max-width:350px){
    ::v-deep .v-expansion-panel-header{
        padding:15px !important;
    }

    ::v-deep .responsiveSpan {
        font-size: .8rem !important;
    }
}
</style>