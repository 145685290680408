<template>
    <div>
        <div>
            <v-btn
            @click="createContrat"
            icon
            depressed class="ma-auto text-capitalize">
                <v-icon  :color="$colors[0]">mdi-text-box-edit</v-icon>
            </v-btn>
        </div>
        
    </div>
</template>
<script>
    import { degrees, PDFDocument, rgb, StandardFonts,layoutMultilineText } from 'pdf-lib'
    import download from 'downloadjs'
    import jsPDF from 'jspdf'
    import 'jspdf-autotable'
    import axios from 'axios'
    export default {
        name: 'TotalHorizon',
        props: {
            donner: null,
            numCompteur: null,
            societe: null,
            donnerEnedis: null,
        },
        data: () => ({
            addData : false,
            dialog: false,
            error: false,
            loading: false,
            messageDialoge: "",
            addAdrsFact: false,
            typeFacture: 'COUR',
            raisonRecus: '',
            adreseeRecus: '',
            adresseEmailRecus: '',
             data:{
                dateAjourdhui: new Date().getDate()+"/"+new Date().getMonth()+"/"+new Date().getFullYear(),
                dateDebut: "",
                dateFin: "",
                pdl: "",
                siret: "", 
                codeNaf: "", 
                adresseSociete: "", 
                adresseCompteur: "",
                 codePostaleCompteur: '',
                 voieCompteur: '',
                 communeCompteur: '',
                 codePostaleSociete: '',
                 voieSociete: '',
                 communeSociete: '',
                codePostale: '',
                nom: "",
                prenom: "", 
                email: "", 
                numTele: "", 
                consommationAnuuelle: "",
                typeCompteur: "",
                kva: "",
                
                coefBase: '',
                coefHp: '',
                coefHc: '',
                prixHC: "",
                prixHP: "",
                prixBASE: "",
                raison: '',
                nbrYears: '',
            },
            rules: {
              isPositiveNumb:v=>(/^[0-9]\d*$/).test(v) || "La valeur doit etre un entier strictement supérieur à 0",        
              required(fieldName){ return value => !!value || `Veuillez saisir ${fieldName}`},
              siretLength: v => v.length == 14 || 'Le siret doit comporter 14 chiffres',
              isMonthCorrect: v => (v >= 1 && v <= 12) || "Le mois doit etre compris entre 1 et 12 ",
              isNumber: v => v.match(/^[0-9]+$/) != null || "Ce champ n'accepte que les chiffres",
              compteurElecLength: v => v.length == 14 || 'Le RAE / PDL est une suite de 14 chiffres',
              isGazCompteur: v => (v.match(/^(GI)[0-9]{6}$/) || (v.length == 14 && v.match(/^[0-9]+$/) != null)) || 'Le numéro PCE/ PDL doit commencer par (GI) ou comporter 14 chiffres',
              phoneLength: v => v.length == 10 || 'Le numéro de téléphone est une suite de 10 chiffres',
              phoneisNumber: v => v.match(/^[0-9]+$/) != null || "Ce champ n'accepte que les chiffres",
              margeMaxMin: v => (v<=25 && v>=2) || 'La marge doit être comprise entre 2 et 25',
              pourcentageEcrete: v => (v<=100 && v>=1) || 'La valeur de pré-écrêté doit être comprise entre 1 et 100',
              Length: v=> (v.length>0) || "Veuillez saisir un type d'offre",
              isBetweenRangeNumber : v => (v>=2 && v<=25) || "La valeur saisie doit etre comprise entre 2 et 25",
              
              emailRules:v => !!v && /.+@.+\.+.+/.test(v) || 'E-mail invalide',
            },
        }),
        
        created() {
            //this.verifyApplicationDataSecurity()
        },
        methods:{
            async createContrat(){
                this.addData = false
                this.dialog= true
                this.error= false
                this.loading = true
                this.messageDialoge= ""

                var autreDonner = {}
                autreDonner.addAdrsFact = this.addAdrsFact
                autreDonner.raisonFact = this.raisonRecus
                autreDonner.adresseFact = this.adreseeRecus
                autreDonner.typeFacture = this.typeFacture
                autreDonner.adresseEmailFact = this.adresseEmailRecus
                var dataToSend = {
                    donner: this.donner,
                    donnerEnedis: this.donnerEnedis,
                    optionTarifaire: this.optionTarifaire,
                    numCompteur: this.numCompteur,
                    societe: this.societe,
                    autreDonner: autreDonner,
                }
                await axios({
                    url: process.env.VUE_APP_URL_EDITION_CONTRAT + '/totalHorizonContratGaz',
                    method: 'POST',
                    data: dataToSend,
                    headers:{'content-type': 'application/json'}
                    
                })
                .then(async (res) => {
                    var doc = await PDFDocument.load(res.data)
                    doc = await doc.save()
                    download(doc, this.numCompteur+'_TotalHorizon_'+this.societe.Raison+'_.pdf', 'application/pdf')
                }).catch((err)=>{
                    console.log(err)
                })
                this.dialog= false
                this.error= false
                this.loading= false
                this.messageDialoge= ""
            },
        }
    }
</script>
<style scoped>
::v-deep .v-input--selection-controls__input + .v-label{
   margin-bottom:0px !important;
}
</style>