<template>
    <v-card class="mt-6 pa-4 mr-2 ml-3" elevation="10">
        <h1 class=" title mb-6">Modifier l'équipe</h1>
        <v-row>
            <v-col md="12" lg="12" xl="12" cols="12" v-if="dialog == true">
                <dialogeModal
                    :error="error"
                    :dialog="dialog"
                    :loading="loadingDialog"
                    @closeDialog="dialog = false"
                    titre="Ajouter une équipe"
                    :messageDialogeSucces="this.messageDialogeSucces"
                    :messageDialogeError="this.messageDialogeError" />
            </v-col>
        </v-row>
        <v-form ref="form" lazy-validation>
            <v-row>
                <v-col cols="12" md="12" lg="4" xl="4" >
                   <v-text-field
                        outlined
                        dense
                        filled
                        solo
                        rounded
                        :rules="[required('un nom d\'équipe')]"
                        v-model="nomEquipe"
                        label="Nom d'équipe"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="12" lg="4" xl="4" >
                    <v-autocomplete
                        outlined
                        dense
                        filled
                        solo
                        rounded
                        :rules="[required('un chef d\'équipe')]"
                        :items="listeChef"
                        item-text="userName"
                        item-value="id"
                        v-model="chefEquipe"
                        label="Chef d'équipe"
                        @change="addTag(chefEquipe)"
                        >
                        <template v-slot:item="data">
                            <template>
                                <v-list-item-content>
                                    <v-list-item-title v-html="data.item.userName"></v-list-item-title>
                                    <v-list-item-subtitle v-html="data.item.type"></v-list-item-subtitle>
                                </v-list-item-content>
                            </template>
                        </template>
                    </v-autocomplete>
                </v-col>
                <v-col cols="12" md="12" lg="6" xl="6">
                    <v-autocomplete 
                        v-model="selectedVendeurs" 
                        :items="Vendeurs" 
                        :loading="isLoading" 
                        hide-no-data 
                        hide-selected 
                        item-text="userName" 
                        item-value="id" 
                        label="Vendeurs" 
                        placeholder="Trouver des vendeurs" 
                        prepend-icon="mdi-account-search-outline"
                        chips
                        deletable-chips
                        multiple
                        :rules="[requiredSelect()]"
                        >
                    
                        <template v-slot:item="data">
                            <template>
                                <v-list-item-content>
                                    <v-list-item-title v-html="data.item.userName"></v-list-item-title>
                                    <v-list-item-subtitle v-html="data.item.type"></v-list-item-subtitle>
                                </v-list-item-content>
                            </template>
                        </template>
                    </v-autocomplete>
                </v-col>
                <v-col cols="12" md="12" lg="6" xl="6">
                    <v-autocomplete 
                        v-model="selectedProspecteurs" 
                        :items="Prospecteurs" 
                        :loading="isLoading" 
                        hide-no-data 
                        hide-selected 
                        item-text="userName" 
                        item-value="id" 
                        label="Prospecteur" 
                        placeholder="Trouver des prospecteurs" 
                        prepend-icon="mdi-account-search-outline"
                        chips
                        deletable-chips
                        multiple
                        :rules="[requiredSelect()]"
                        >
                        <template v-slot:item="data">
                            <template>
                                <v-list-item-content>
                                    <v-list-item-title v-html="data.item.userName"></v-list-item-title>
                                    <v-list-item-subtitle v-html="data.item.type"></v-list-item-subtitle>
                                </v-list-item-content>
                            </template>
                        </template>
                    </v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                    <v-btn @click="$emit('modifierEquipe', false)" text>Annuler</v-btn>
                </v-col>
                <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                    <v-btn @click="modifierEquipeAdvFunc" style="color: #fff;background: #FF6600;">Modifier l'équipe</v-btn>
                </v-col>
            </v-row>
        </v-form>
    </v-card>
</template>
<script>
    import axios from "axios";
    import Multiselect from 'vue-multiselect'
    import dialogeModal from '../../components/ui/dialogeModal'
    export default {
        name: "modifierEquipeAdv",
         props: {
            equipeId: null,
        },
        components: {
            dialogeModal:dialogeModal,
            Multiselect:Multiselect
        },
        data() {
            return {
                //dialoge delete
                idItemDeleted: null,
                dialogDelete:false,
                search: '',
                error: false,
                dialog: false,
                loadingDialog: false,
                messageDialogeSucces: "",
                messageDialogeError: "",
                listeChef:[],
                listeEquipe: [],
                Vendeurs: [],
                Prospecteurs: [],
                nomEquipe: "",
                selectedVendeurs: [],
                selectedProspecteurs: [],
                ADV: null,
                chefEquipe:"",
                isLoading: false,
                required(fieldName) {
                    return (value) => ((!!value && value >=0)||(!!value  && value != '')) || `Veuillez saisir ${fieldName}`
                },
                requiredSelect(){
                    return (value) => (value.length>=0 || "Sélectionner au moins un utilisateur.")
                },
                rules: {
                    required: (value) => !!value || "Ce champ est obligatoire.",
                },
            }
        },
        async created() {
            var whoIam = ""
            await this.whoIAm(this.getToken('token')).then((data)=>{whoIam=data});
            if(whoIam == "noToken"){
                localStorage.removeItem('vendeurName')
                localStorage.removeItem('typeUser') 
                localStorage.removeItem('token')
                this.$router.push('/login')
            }else{
            localStorage.setItem('typeUser', whoIam)
            }
            this.typeUser = localStorage.getItem("typeUser");
            this.getEquipeById()
            this.getAllChef()
            this.getAllVendeurs()
            this.getAllProspecteur()
        },
        computed: {
            filteredList() {
                return this.listeEquipe.filter(post => {
                    return post.nomEquipe.toLowerCase().includes(this.search.toLowerCase())
                })
            }
        },
        methods: {
            emit(){
                this.$emit('modifierEquipe',{varr:false})
            },
            reinitialiser(){
                this.nomEquipe= ""
                this.chefEquipe=""
                this.listeChef=[]
                this.ADV= null
                this.selectedVendeurs= []
                this.Vendeurs= []
                this.Prospecteurs= []
                this.selectedProspecteurs= []
                this.isLoading= false
                this.getAllChef()
                this.getAllVendeurs()
                this.getAllProspecteur()
            },
            getListIdsFromObjectList(list){
               // how to check if the list it's an list of object or list of ids
                if(list.length > 0){
                    if(list[0].id){
                        return list.map(x => x.id)
                    }else{
                        return list
                    }
                }else{
                    return list
                }
            },
            async getEquipeById(){
                var formData = new FormData()
                formData.append('token', this.getToken('token'))
                formData.append('id', this.equipeId)
                await axios({
                    url: process.env.VUE_APP_URL_API_CLIENT + 'getEquipeById/',
                    method: 'POST',
                    data: formData,
                }).then((res)=>{
                    if(res.data.error == false){
                        var equipe = res.data.result
                        this.nomEquipe = equipe.nomEquipe
                        this.chefEquipe = equipe.chefEquipe
                        this.ADV = equipe.ADV
                        this.selectedProspecteurs = equipe.prospecteur
                        this.selectedVendeurs = equipe.Vendeurs
                    }else if(res.data.error == "permission"){
                        alert(res.data.message)
                        localStorage.removeItem('vendeurName')
                        localStorage.removeItem('typeUser')
                        localStorage.removeItem('token')
                        this.$router.push('/login')
                    }
                }).catch((err)=>{
                    console.log(err)
                })
                
            },
            async modifierEquipeAdvFunc(){
                this.$refs.form.validate();
                if (this.$refs.form.validate()) {
                    this.dialog=true
                    this.loadingDialog=true
                    var formData = new FormData()
                    var selectedChef = this.getListIdsFromObjectList([this.chefEquipe])
                    var selectedVendeurs = this.getListIdsFromObjectList(this.selectedVendeurs)
                    var selectedProspecteurs = this.getListIdsFromObjectList(this.selectedProspecteurs)

                    formData.append('token', this.getToken('token'))
                    formData.append('idEquipe', this.equipeId)
                    formData.append('nomEquipe', this.nomEquipe)
                    formData.append('ADV', this.ADV)
                    formData.append('chefEquipe', selectedChef)
                    formData.append('vendeurs',selectedVendeurs)
                    formData.append('prespecteurs',selectedProspecteurs)
                    await axios({
                        url: process.env.VUE_APP_URL_API_CLIENT + 'modifierEquipeAdv/',
                        method: 'POST',
                        data: formData,
                    }).then((res)=>{
                        if(res.data.error == false){
                            this.loadingDialog=false
                            this.error = false
                            this.messageDialogeSucces= res.data.message
                            this.messageDialogeError= ""
                            this.reinitialiser()
                            this.emit()
                        }else if(res.data.error == true){
                            this.loadingDialog=false
                            this.error = true
                            this.messageDialogeSucces= ""
                            this.messageDialogeError= res.data.message
                        }else if(res.data.error == "permission"){
                            alert(res.data.message)
                            localStorage.removeItem('vendeurName')
                            localStorage.removeItem('typeUser')
                            localStorage.removeItem('token')
                            this.$router.push('/login')
                        }
                        this.getAllChef()
                        this.getAllVendeurs()
                        this.getAllProspecteur()
                    }).catch((err)=>{
                        console.log(err)
                        this.reinitialiser()
                        this.getAllChef()
                        this.getAllVendeurs()
                        this.getAllProspecteur()
                        this.emit()
                    })
                }
            },
            async getAllChef(){
                var formData = new FormData()
                formData.append('token', this.getToken('token'))
                formData.append('idEquipe', this.equipeId)
                await axios({
                    url: process.env.VUE_APP_URL_API_CLIENT + 'getAllChefEquipeAndTout/',
                    method: 'POST',
                    data: formData,
                }).then((res)=>{
                    if(res.data.error == false){
                        this.listeChef = res.data.result
                    }else if(res.data.error == "permission"){
                        alert(res.data.message)
                        localStorage.removeItem('vendeurName')
                        localStorage.removeItem('typeUser')
                        localStorage.removeItem('token')
                        this.$router.push('/login')
                    }
                }).catch((err)=>{
                    console.log(err)
                })
            },
            async getAllVendeurs(){
                var formData = new FormData()
                formData.append('token', this.getToken('token'))
                await axios({
                    url: process.env.VUE_APP_URL_API_CLIENT + 'getAllVendeursAndTout/',
                    method: 'POST',
                    data: formData,
                }).then((res)=>{
                    if(res.data.error == false){
                        this.Vendeurs = res.data.result
                    }else if(res.data.error == "permission"){
                        alert(res.data.message)
                        localStorage.removeItem('vendeurName')
                        localStorage.removeItem('typeUser')
                        localStorage.removeItem('token')
                        this.$router.push('/login')
                    }
                }).catch((err)=>{
                    console.log(err)
                })
            },
            async getAllProspecteur(){
                var formData = new FormData()
                formData.append('token', this.getToken('token'))
                await axios({
                    url: process.env.VUE_APP_URL_API_CLIENT + 'getAllProspecteurAndTout/',
                    method: 'POST',
                    data: formData,
                }).then((res)=>{
                    if(res.data.error == false){
                        this.Prospecteurs = res.data.result
                    }else if(res.data.error == "permission"){
                        alert(res.data.message)
                        localStorage.removeItem('vendeurName')
                        localStorage.removeItem('typeUser')
                        localStorage.removeItem('token')
                        this.$router.push('/login')
                    }
                }).catch((err)=>{
                    console.log(err)
                })
            },
            addTag (newTag) {
                var index = this.Vendeurs.findIndex(x => x.id === newTag)
                if (index > -1) { 
                    this.Vendeurs.splice(index, 1);
                }
                
                var index = this.Prospecteurs.findIndex(x => x.id === newTag)
                if (index > -1) { 
                    this.Prospecteurs.splice(index, 1);
                }
                var index = this.selectedProspecteurs.findIndex(x => x === newTag)
                if (index > -1) { 
                    this.selectedProspecteurs.splice(index, 1);
                }
                var index = this.selectedVendeurs.findIndex(x => x === newTag)
                if (index > -1) { 
                    this.selectedVendeurs.splice(index, 1);
                }
            }
        }
    }
</script>