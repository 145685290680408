<template>
    <div>
    <div class="container mb-4"> 
        <div class="d-flex align-items-center ">
            <p class="ma-0">Afficher</p>
            <select class="form-control selected" v-model="length" @change="$emit('selectNumberOfRecords', {'type':'nbrRecords', 'key':length})">
                <option v-for="(records, index) in pagination.perPage" :key="index" :value="records">{{records}}</option>
            </select> 
            <p class="ma-0">éléments</p>
        </div>
        <nav aria-label="Page navigation example">
        
            <ul class="pagination">  
                <li class="page-item">
                    <a v-if="pagination.prevPageUrl>=0" class="page-link" @click="$emit('first');">
                    &lt;&lt;</a>
                    <a class="page-link" v-else :disabled="true">
                    &lt;&lt;</a>
                </li>
                <li class="page-item">
                    <a v-if="pagination.prevPageUrl>=0" class="page-link" @click="$emit('prev');">
                    &lt;</a>
                    <a class="page-link" v-else :disabled="true">
                    &lt;</a>
                </li>
                
                <li class="page-item" v-if="pagination.prevPageUrl>=0">
                    <a v-if="pagination.prevPageUrl>=0" class="page-link" @click="$emit('prev');">
                    {{pagination.prevPageUrl}}</a>
                    <a class="page-link" v-else :disabled="true">
                    {{pagination.prevPageUrl}}</a>
                </li>
                <li class="page-item active">
                    <span class="page-link">
                        {{pagination.currentPage}}
                    </span>
                </li>
                <li class="page-item">
                    <a v-if="pagination.nextPageUrl<=pagination.lastPage" class="page-link" @click="$emit('next');">
                        {{pagination.nextPageUrl}}
                    </a>
                    <a class="page-link" v-else :disabled="true">
                        {{pagination.nextPageUrl}}
                    </a>
                </li>
                <li class="page-item">
                    <a v-if="pagination.nextPageUrl<=pagination.lastPage" class="page-link" @click="$emit('next');">
                        &gt;
                    </a>
                    <a class="page-link" v-else :disabled="true">
                        &gt;
                    </a>
                </li>
                <li class="page-item">
                    <a v-if="pagination.nextPageUrl<=pagination.lastPage" class="page-link" @click="$emit('last');">
                        &gt;&gt;
                    </a>
                    <a class="page-link" v-else :disabled="true">
                        &gt;&gt;
                    </a>
                </li>
            </ul>    
        </nav>
        <li class="page-stats">
            <span>{{pagination.from}} - {{pagination.to}} sur {{pagination.total}}</span>
        </li>
    </div>
    </div>
</template>

<script>
    export default{
        props: ['pagination'],
        data(){
            return{
                perPage: ['15', '20', '30'],
                length:15,
            }
        },
    }
</script>
<style scoped>
    .pagination{
        justify-center:center;
        margin:0px;
    }
    .page-stats{
        list-style-type: none; 
        margin-left:24px;
    }
    .container{
        display:flex;
        align-items:center;
        margin-left:16px;
        padding:0
    }
    .selected{
        width:50px;
        margin-left:5px;
        margin-right:5px
    }
    
@media only screen and (max-width:1280px){
     .container{
        flex-direction:column;
        margin:0px !important;
    }
}
</style>
<style scoped>
@media only screen and (max-width:1280px){
     .page-stats{
        margin-left:0px;
    }
}
</style>
<style scoped>
@media only screen and (max-width:1280px){
     .pagination{
        margin-top:10px;
        margin-bottom:10px;
    }
}
</style>