<template>

    <section style="background-color: #eee;">
        <div class="container-fluid pt-5" style="height: 100%;">
            <div class="row">
                <div class="col-lg-12">
                    <div class="card mb-4">
                        <ListeDesPropositions class='pt-8' v-if="cotation!=null" 
                            :optionTarifaire="cotation.optionTarifaire" 
                            :parametreCompteur="cotation.cotation.typeCompteur" 
                            :Propositions="cotation.cotation.result" 
                            :SocieteData="cotation.cotation.dataSociete" 
                            :enedisData="cotation.cotation.dataEnedis"
                            :numCompteur="cotation.numCompteur"
                            :turpeData="cotation.cotation.dataTurpe" 
                            :taxeData="cotation.cotation.dataTaxe" 
                            :typeCompteur="cotation.cotation.typeCompteur" 
                            :fournisseurActuelle="cotation.cotation.result.actuelle"
                            :selected = "[]"/>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>


<script>
    import "jquery/dist/jquery.min.js";
    import "bootstrap/dist/css/bootstrap.min.css";
    import "bootstrap/dist/css/bootstrap.css";
    import "bootstrap/dist/js/bootstrap.bundle";
    import "datatables.net-dt/js/dataTables.dataTables";
    import "datatables.net-dt/css/jquery.dataTables.min.css";
    import $ from 'jquery'
    import jQuery from 'jquery'
    import axios from 'axios'
    
    import ListeDesPropositions from '../../components/proposition-professionnels-elec/ListeDesPropositions.vue'
    export default{
        components: {
            ListeDesPropositions,
        },
        name: 'historiqueCotation',
        data() {
            return {
                cotation: null,
            }
        },
        created() {
            this.getCotation();
        },
        methods: {
            getCotation(){
                var formData = new FormData()
                formData.append('token', this.getToken('token'))
                formData.append('idCotation', this.$route.query.id)
                axios({
                    url:
                    process.env.VUE_APP_URL_API_CLIENT +
                    'getAncienCotation/',
                    method: 'POST',
                    data: formData,
                })
                .then((res) => {
                    if(res.data.result==true){
                        this.cotation = res.data.cotation
                    }else if(res.data.result==false){
                        alert("La société que vous cherchez ne semble pas exister")
                    }else if(res.data.result=="permission"){
                        alert('permission')
                        localStorage.removeItem('vendeurName')
                        localStorage.removeItem('typeUser') 
                        localStorage.removeItem('token')
                        this.$router.push('/login')
                    }
                }).catch((err)=>{
                    alert('permission refusée')
                    localStorage.removeItem('vendeurName')
                    localStorage.removeItem('typeUser') 
                    localStorage.removeItem('token')
                    this.$router.push('/login')
                })
            }
        }
    }

</script>