<template>
  <div class="d-flex justify-end">
    <v-col md="12" lg="12" xl="12" cols="12" v-if="dialogPres == true">
      <loadingComponent @closeDialog="dialogPres = $event" :dialogProps="dialogPres" :loadingProps="loadingPres" />
    </v-col>
    <v-btn :disabled="isDisabled" :color="$colors[0]" class="white--text mr-2" @click="createPres"><span
        class="Poppins-Bold">Créer une présentation</span><v-icon class="ml-6" right dark>
        mdi-file-document-multiple
      </v-icon></v-btn>
  </div>
</template>

<script>
import { degrees, PDFDocument, rgb, PDFName, PDFString, StandardFonts, TextAlignment, setFillingRgbColor, setFontAndSize } from 'pdf-lib'
import download from 'downloadjs'
import jsPDF from 'jspdf'
import loadingComponent from "../../../components/ui/loading-component.vue"
import axios from 'axios'
import 'jspdf-autotable'
export default {
  name: "presentation",
  components: {
    loadingComponent
  },
  props: {
    donnerDesFournisseur: [],
    fournisseurActuelle: null,
    indexes: [],
    numCompteur: null,
    donnerCompteur: [],
    societe: [],
  },
  data() {
    return {
      dialogPres: false,
      loadingPres: false,
      listDesOffre: [],
      Offres: [],
      OffresRecap: [],
      isDisabled: true,
    }
  },
  watch: {
    indexes:{
      immediate: true,
      deep: true,
      handler (val, oldVal) {
        console.log(val)
          if(this.donnerDesFournisseur.length>0){
              this.isDisabled = true
              for(var i=0; i<val.length; i++){
                  var isActuelle = false
                  if('resultat' in this.donnerDesFournisseur[i]){ 
                      if(this.donnerDesFournisseur[i]['resultat']['actuelle'] == true){
                          isActuelle = true
                      }
                  }
                  if(isActuelle == true){
                      if(val[i].length>1){
                          this.isDisabled = false
                      }  
                  }else{
                      if(val[i].length>0){
                          this.isDisabled = false
                      }  
                  }
              }
          }
      }
    }
  },
  methods: {
    async getCodePostale(adresse) {
      var myRe = new RegExp('\\d\\d\\d\\d\\d', '');
      var myArray = myRe.exec(adresse);
      var voie = ""
      var codePostale = ""
      var commune = ""
      if (myArray != null) {
        codePostale = myArray[0]
        if (adresse.split(codePostale).length >= 2) {
          voie = adresse.split(codePostale)[0]
          commune = adresse.split(codePostale)[1]
        } else {
          commune = ""
          voie = adresse
        }
      } else {
        voie = adresse
      }
      return [codePostale, voie, commune]
    },
    getToutLesOffre() {
      if (this.indexes.length > 0) {
        this.indexes.sort((a, b) => { return a - b; });
      }
      this.listDesOffre = []
      for (var i = 0; i < this.indexes.length; i++) {
        this.listDesOffre.push(this.donnerDesFournisseur[this.indexes[i]])
      }
    },
    formatDate(date) {
      return [
        date.getDate(),
        date.getMonth() + 1,
        date.getFullYear(),
      ].join('/');
    },
    getDateFormatSlach(date) {
      if (date.indexOf('-') != -1) {
        return date.split('-')[2] + '/' + date.split('-')[1] + '/' + date.split('-')[0]
      }else 
      return date
    },
    async getImage(link, doc) {
      const ImageLogo = await fetch(link).then((res) =>
        res.arrayBuffer()
      )
      var pngImage = await doc.embedPng(ImageLogo)
      return pngImage
    },
    async createTextFieldPers(fontsize, textColor, multiLine, form, page, x, y, width, height, txt, name, bgColor) {

      var textField = form.createTextField(name)
      textField.setText(txt)
      if (multiLine == true) {
        textField.enableMultiline()
      }
      textField.setAlignment(TextAlignment.Left)
      textField.addToPage(page, {
        x: x,
        y: y,
        width: width,
        height: height,
        textColor: rgb(0.062, 0.227, 0.368),
        backgroundColor: rgb(bgColor[0], bgColor[1], bgColor[2]),
        borderWidth: 0,
      })
      textField.acroField.setDefaultAppearance(setFontAndSize('Courier', fontsize).toString() + setFillingRgbColor(textColor[0], textColor[1], textColor[2]).toString())
      var fields = form.getFields()
      fields.forEach(field => {
        field.enableReadOnly()
      })
      form.updateFieldAppearances()
    },
    async createTextField(multiLine, form, page, x, y, width, height, txt, name, bgColor) {
      var fontsize = 9
      /////////////////////
      if (width / txt.length <= 3.5) {
        if (name.indexOf("value") != -1 && name.indexOf("TypeOffre") != -1) {
          if (width / txt.length <= 2) {
            fontsize = 6.5
            height = height + 2.5
          } else {
            fontsize = 8
          }
        } else {
          fontsize = 8
        }
        multiLine = true
      }

      let rand = (Math.random() + 1).toString(36).substring(7);
      var textField = form.createTextField(name + rand)
      if (isNaN(txt)) {
        textField.setText(txt)
      } else {
        if (txt != "" && name != "pdl") {
          var temText = Number(txt).toLocaleString("fr-FR", { minimumFractionDigits: 1 })
          temText = temText.replace(/ /g, ' ');
          textField.setText(temText)
        } else {
          textField.setText(txt)
        }
      }
      if (multiLine == true) {
        textField.enableMultiline()
      }
      if (name == "title8BigPourcentage") {
        textField.enableMultiline()
        textField.setAlignment(TextAlignment.Center)
        textField.addToPage(page, {
          x: x,
          y: y,
          width: width,
          height: height + 20,
          textColor: rgb(0.062, 0.227, 0.368),
          backgroundColor: rgb(bgColor[0], bgColor[1], bgColor[2]),
          borderWidth: 0,
        })

      } else {
        textField.setAlignment(TextAlignment.Center)
        textField.addToPage(page, {
          x: x,
          y: y,
          width: width,
          height: height,
          textColor: rgb(0.062, 0.227, 0.368),
          backgroundColor: rgb(bgColor[0], bgColor[1], bgColor[2]),
          borderWidth: 0,
        })

      }
      textField.acroField.setDefaultAppearance(setFontAndSize('Courier', fontsize).toString() + setFillingRgbColor(0.062, 0.227, 0.368).toString())
      var fields = form.getFields()
      fields.forEach(field => {
        field.enableReadOnly()
      })
      form.updateFieldAppearances()
    },
    async infoTable(form, pdfDoc, page, donner) {

      var HelveticaBold = await pdfDoc.embedFont(StandardFonts.HelveticaBold)
      var Helvetica = await pdfDoc.embedFont(StandardFonts.Helvetica)
      page.drawText("RESULTATS DE L'APPEL D'OFFRES DU " + this.formatDate(new Date()), {
        x: 175,
        y: 545,
        font: HelveticaBold,
        size: 20,
        color: rgb(0.062, 0.227, 0.368),
      })
      page.drawText("Informations Compteur", {
        x: 65,
        y: 515,
        font: HelveticaBold,
        size: 15,
        color: rgb(0.062, 0.227, 0.368),
      })
      var image = await this.getImage("/imagePres/yellow.PNG", pdfDoc)
      var xBegin = donner['xBegin']
      var yBegin = donner['yBegin']
      for (var i = 0; i < donner['data'].length; i++) {
        var data = donner['data']
        var width = data[i]['width']
        //var title = data[i]['title']
        var lesTexts = data[i]['text']
        var fieldName = data[i]['fieldName']

        page.drawImage(image, { x: xBegin, y: yBegin, width: width, height: 32, })
        //await this.createTextField(false, form, page, xBegin, yBegin+49, width, 15, title, "title"+fieldName, [1, 1,1])
        var xForm = xBegin + 10
        for (var j = 0; j < lesTexts.length; j++) {
          var text = lesTexts[j]
          var subTitle = text['title']
          var value = text['value']
          var fieldName = text['fieldName']
          if (lesTexts.length > 2) {
            var widthForm = (width / lesTexts.length) - 8
          } else if (lesTexts.length == 2) {
            var widthForm = (width / lesTexts.length) - 12
          } else {
            var widthForm = width - 15
          }
          await this.createTextField(false, form, page, xForm, yBegin + 30, widthForm, 15, subTitle, "subTitle" + fieldName, [1, 1, 1])

          try {
            if (subTitle == "Compteur" || (value.indexOf('/') != -1) || isNaN(parseFloat(value).toFixed(2))) {
              value = value
            } else {
              value = parseFloat(value).toFixed(2);
            }
          } catch (exception) {
            value = value
          }
          await this.createTextField(false, form, page, xForm, yBegin + 5, widthForm, 22, value, fieldName, [1, 0.796, 0.207])
          xForm = xForm + widthForm + 5
        }
        xBegin = xBegin + width
      }
      return pdfDoc
    },
    async drawOffreActuelle(form, pdfDoc, page, donner) {
      var HelveticaBold = await pdfDoc.embedFont(StandardFonts.HelveticaBold)
      var Helvetica = await pdfDoc.embedFont(StandardFonts.Helvetica)
      var y = 380
      page.drawText("Offre de renouvellement / actuelle", {
        x: 65,
        y: y,
        font: HelveticaBold,
        size: 15,
        color: rgb(0.062, 0.227, 0.368),
      })

      var yellow = await this.getImage("/imagePres/yellow.PNG", pdfDoc)
      var tableLine = await this.getImage("/imagePres/tableYellowRecap.PNG", pdfDoc)

      var xBegin = donner['xBegin']
      var yBegin = 275
      var width = 515
      var logo = await this.getImage(donner['logo'], pdfDoc)
      page.drawImage(logo, { x: xBegin - 112, y: yBegin + 4, width: 115, height: 35 })
      //page.drawImage(tableLine, {x: xBegin, y: yBegin+2, width: width,height: 40,})
      var smallWidth = 80
      //page.drawImage(yellow, {x: xBegin+width, y: yBegin+2, width: smallWidth,height: 40,})
      var xForm = xBegin
      var widthForm = 68
      var value = ""
      var title = ""
      var subtitle = ""
      var fieldName = ""
      for (var i = 0; i < donner['data'].length; i++) {
        var data = donner['data'][i]
        xForm = xBegin + data['pas']
        widthForm = data['width']
        value = data['value']
        if ('title' in data) {
          title = data['title']
          await this.createTextField(true, form, page, xForm, yBegin + 56, widthForm + 2, 20, title, "title" + fieldName, [1, 1, 1])
        }
        if ('subTitle' in data) {
          subtitle = data['subTitle']
          await this.createTextField(true, form, page, xForm, yBegin + 40, widthForm, 15, subtitle, "subtitle" + fieldName, [1, 1, 1])
        }
        fieldName = data['fieldName']
        xForm = xBegin + data['pas']
        try {
          if (title == "Durée" || isNaN(parseFloat(value).toFixed(2))) {
            value = value
          } else {
            value = parseFloat(value).toFixed(2);
          }
        } catch (exception) {
          value = value
        }
        page.drawImage(yellow, { x: xForm, y: yBegin + 5, width: widthForm + 5, height: 37, })
        await this.createTextField(false, form, page, xForm + 3, yBegin + 12, widthForm - 1, 21, value, "value" + fieldName, [1, 0.796, 0.207])
        xBegin = xBegin + widthForm + 2 + data['pas']
      }

      return pdfDoc
    },
    filterArray(inputArr) {
      var found = {};
      var out = inputArr.filter(function (element) {
        return found.hasOwnProperty(element) ? false : (found[element] = true);
      });
      return out;
    },
    async drawOffres(form, pdfDoc, page, donner, fournisseurActuelle) {
      var yellow = await this.getImage("/imagePres/yellow.PNG", pdfDoc)
      var tableLine = await this.getImage("/imagePres/tableYellowRecap.PNG", pdfDoc)
      var green = await this.getImage("/imagePres/green.PNG", pdfDoc)
      var greenLine = await this.getImage("/imagePres/tableGreenRecap.PNG", pdfDoc)
      var HelveticaBold = await pdfDoc.embedFont(StandardFonts.HelveticaBold)
      var Helvetica = await pdfDoc.embedFont(StandardFonts.Helvetica)
      var y = (fournisseurActuelle) ? 260 : 330
      page.drawText("Nos meilleures offres", {
        x: 65,
        y: y,
        font: HelveticaBold,
        size: 15,
        color: rgb(0.062, 0.227, 0.368),
      })
      var xBegin = donner['xBegin']
      var yBegin = (fournisseurActuelle) ? 178 : 240
      var actuelle = donner['actuelle']
      var width = 515

      var xForm = xBegin
      var widthForm = 0
      var value = ""
      var title = ""
      var subtitle = ""
      var fieldName = ""
      for (var i = 0; i < donner['titles'].length; i++) {
        var data = donner['titles'][i]
        xForm = xBegin + data['pas']
        widthForm = data['width']
        value = data['value']
        title = data['title']
        subtitle = data['subTitle']
        fieldName = data['fieldName']
        xForm = xBegin + data['pas']
        await this.createTextField(false, form, page, xForm, yBegin + 61, widthForm, 20, title, "title" + i + fieldName, [1, 1, 1])
        await this.createTextField(false, form, page, xForm, yBegin + 44, widthForm, 15, subtitle, "subtitle" + i + fieldName, [1, 1, 1])
        xBegin = xBegin + widthForm + 2 + data['pas']
      }
      var xForm = xBegin
      var widthForm = 68
      var value = ""
      var fieldName = ""
      for (var i = 0; i < donner['data'].length; i++) {
        xBegin = donner['xBegin']
        var itemData = donner['data'][i]
        var logo = await this.getImage(itemData[0]['value'], pdfDoc)
        switch (itemData[0]['value']) {
          case "/logoParticulier/OHM_120X35.png":
            page.drawImage(logo, { x: xBegin - 112, y: yBegin + 4, width: 115, height: 35, })
            break;
          case "/logoParticulier/Ekwateur_120X35.png":
            page.drawImage(logo, { x: xBegin - 112, y: yBegin + 4, width: 115, height: 33, })
            break;
          default:
            page.drawImage(logo, { x: xBegin - 112, y: yBegin + 4, width: 115, height: 35, })
            break;
        }
        for (var j = 1; j < itemData.length; j++) {
          var data = itemData[j]
          xForm = xBegin + data['pas']
          widthForm = data['width']
          value = data['value']
          fieldName = data['fieldName']
          xForm = xBegin + data['pas']
          try {
            if (value.indexOf("MOIS") != -1 || isNaN(parseFloat(value).toFixed(2))) {
              value = value
            } else {
              value = parseFloat(value).toFixed(2);
            }
          } catch (exception) {
            value = value
          }
          if (i == 0) {
            var smallWidth = 80

            page.drawImage(green, { x: xForm, y: yBegin + 5, width: widthForm + 5, height: 37, })
            var colorBackGroundTextField = [0.662, 0.941, 0.819];
          } else {
            var smallWidth = 80
            page.drawImage(yellow, { x: xForm, y: yBegin + 5, width: widthForm + 5, height: 37, })
            var colorBackGroundTextField = [1, 0.796, 0.207];
          }
          await this.createTextField(false, form, page, xForm + 3, yBegin + 12, widthForm - 1, 21, value, "value" + i + j + fieldName, colorBackGroundTextField)
          xBegin = xBegin + widthForm + 2 + data['pas']
        }

        yBegin = yBegin - 33

        if (yBegin < 45 && i != donner['data'].length - 1) {
          page = pdfDoc.addPage([842, 595])
          yBegin = 450
        }

        var imageBorder = await this.getImage("/imagePres/border-presentation.png", pdfDoc)
        page.drawImage(imageBorder, { x: 767, y: 0, width: 75, height: 75 })
      }
      return [pdfDoc, page, yBegin]
    },
    async drawOffresRecap(form, pdfDoc, page, donner, fournisseurActuelle) {

      page.drawText("RECAPITULATIF DE L'APPEL D'OFFRES DU " + this.formatDate(new Date()), {
        x: 115,
        y: 490,
        font: HelveticaBold,
        size: 25,
        color: rgb(0.062, 0.227, 0.368),
      })
      var yellow = await this.getImage("/imagePres/yellow.PNG", pdfDoc)
      var tableLine = await this.getImage("/imagePres/tableYellowRecap.PNG", pdfDoc)
      var green = await this.getImage("/imagePres/green.PNG", pdfDoc)
      var greenLine = await this.getImage("/imagePres/tableGreenRecap.PNG", pdfDoc)
      var HelveticaBold = await pdfDoc.embedFont(StandardFonts.HelveticaBold)
      var Helvetica = await pdfDoc.embedFont(StandardFonts.Helvetica)
      var y = 320
      var xBegin = donner['xBegin']
      var yBegin = 330
      var actuelle = donner['actuelle']
      var width = 515

      var xForm = xBegin
      var widthForm = 0
      var value = ""
      var title = ""
      var subtitle = ""
      var fieldName = ""
      for (var i = 0; i < donner['titles'].length; i++) {
        var data = donner['titles'][i]
        xForm = xBegin + data['pas']
        widthForm = data['width']
        value = data['value']
        title = data['title']
        subtitle = data['subTitle']
        fieldName = data['fieldName']
        xForm = xBegin + data['pas']
        await this.createTextField(false, form, page, xForm, yBegin + 61, widthForm, 20, title, "title" + i + fieldName, [1, 1, 1])
        await this.createTextField(false, form, page, xForm, yBegin + 44, widthForm, 15, subtitle, "subtitle" + i + fieldName, [1, 1, 1])
        xBegin = xBegin + widthForm + 2 + data['pas']
      }
      
        var IndexMoinscher = 0
        if (fournisseurActuelle == true) {
            IndexMoinscher = 1
        }
      var xForm = xBegin
      var widthForm = 68
      var value = ""
      var fieldName = ""
      for (var i = 0; i < donner['data'].length; i++) {
        xBegin = donner['xBegin']
        var itemData = donner['data'][i]
        var logo = await this.getImage(itemData[0]['value'], pdfDoc)
        switch (itemData[0]['value']) {
          case "/logoParticulier/OHM_120X35.png":
            page.drawImage(logo, { x: xBegin - 122, y: yBegin + 4, width: 120, height: 37, })
            break;
          case "/logoParticulier/Ekwateur_120X35.png":
            page.drawImage(logo, { x: xBegin - 122, y: yBegin + 4, width: 120, height: 33, })
            break;
          default:
            page.drawImage(logo, { x: xBegin - 122, y: yBegin + 4, width: 120, height: 37, })
            break;
        }
        for (var j = 1; j < itemData.length; j++) {
          var data = itemData[j]
          xForm = xBegin + data['pas']
          widthForm = data['width']
          value = data['value']
          fieldName = data['fieldName']
          xForm = xBegin + data['pas']
          try {
            if (value.indexOf("MOIS") != -1 || isNaN(parseFloat(value).toFixed(2))) {
              value = value
            } else {
              value = parseFloat(value).toFixed(2);
            }
          } catch (exception) {
            value = value
          }
          if (i == IndexMoinscher) {
            var smallWidth = 80

            page.drawImage(green, { x: xForm, y: yBegin + 4, width: widthForm + 5, height: 37, })
            var colorBackGroundTextField = [0.662, 0.941, 0.819];
          } else {
            var smallWidth = 80
            page.drawImage(yellow, { x: xForm, y: yBegin + 4, width: widthForm + 5, height: 37, })
            var colorBackGroundTextField = [1, 0.796, 0.207];
          }
          await this.createTextField(false, form, page, xForm + 3, yBegin + 13, widthForm - 1, 20, value, "value" + i + j + fieldName, colorBackGroundTextField)
          xBegin = xBegin + widthForm + 2 + data['pas']
        }

        yBegin = yBegin - 33

        if (yBegin < 45 && i != donner['data'].length - 1) {
          page = pdfDoc.addPage([842, 595])
          yBegin = 450
        }

        var imageBorder = await this.getImage("/imagePres/border-presentation.png", pdfDoc)
        page.drawImage(imageBorder, { x: 767, y: 0, width: 75, height: 75 })
      }
      return [pdfDoc, page, yBegin]
    },
    async addPagesToPdf(mergedPdf, donorPdf) {
      const copiedPages = await mergedPdf.copyPages(
        donorPdf,
        donorPdf.getPageIndices()
      )
      copiedPages.forEach((page) => {
        mergedPdf.addPage(page)
      })
      return mergedPdf
    },
    base64ToArrayBuffer(base64) {
      var binary_string = window.atob(base64);
      var len = binary_string.length;
      var bytes = new Uint8Array(len);
      for (var i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i);
      }
      return bytes.buffer;
    },
    async createPres() {
      this.loadingPres = true;
      this.dialogPres = true;
      if (this.indexes.length > 0) {
        var pdfDoc = await PDFDocument.create()
        const presentationURLPartBegin = '/pdf/PresentationGaz-3-5.pdf'
        const presentationPartBeginBytes = await fetch(presentationURLPartBegin).then((res) =>
          res.arrayBuffer()
        )
        var presentationPagePartBeginDoc = await PDFDocument.load(presentationPartBeginBytes)
        const HelveticaBoldFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold)

        const TimesRomanFont = await presentationPagePartBeginDoc.embedFont(StandardFonts.TimesRomanBold)
        pdfDoc = await this.addPagesToPdf(pdfDoc, presentationPagePartBeginDoc)



        let fournisseurList = []
        // loop through all compteurs and create pages 
        for (let [k, fournisseur] of this.donnerDesFournisseur.entries()) {
          /// les information pour chaque compteur
          let typeCompteur = fournisseur["resultat"]["typeCompteur"];
          let numCompteur = fournisseur["resultat"]["numCompteur"];
          let donnerCompteur = fournisseur["resultat"]["donnerCompteur"];
          let fournisseurActuelle = fournisseur["resultat"]["actuelle"];
          // filter les offre sélectionnée pour ce compteur
          let listDesOffre = fournisseur["resultat"]["result"].filter((item, ind) => {
            return this.indexes[k]?.includes(ind);
          });

          if (listDesOffre.length == 0){
            continue
          }
          var page = pdfDoc.addPage([842, 595])

          var form = pdfDoc.getForm()
          if (k == 0) {
            page.drawText('Vos informations', {
              x: 500,
              y: 515,
              size: 15,
              font: HelveticaBoldFont,
              color: rgb(0.062, 0.227, 0.368)
            })
            var adressePostal = this.donnerDesFournisseur[0]["resultat"]["dataSociete"]['AdressePostal'].replace(this.donnerDesFournisseur[0]["resultat"]["dataSociete"]['Raison'], "")
            var adresseSocieteSeparer = await this.getCodePostale(adressePostal)
            if (!adresseSocieteSeparer[0]) {
              adresseSocieteSeparer[0] = ""
            }
            if (!adresseSocieteSeparer[1]) {
              adresseSocieteSeparer[1] = ""
            }
            if (!adresseSocieteSeparer[2]) {
              adresseSocieteSeparer[2] = ""
            }
            await this.createTextFieldPers(11, [0.10, 0.17, 0.35], true, form, page, 510, 465, 200, 40, `SIRET : ${this.donnerDesFournisseur[0]["resultat"]["dataSociete"]['siret']}`, "siretConsommation", [1, 1, 1])
            await this.createTextFieldPers(11, [0.10, 0.17, 0.35], true, form, page, 510, 445, 200, 40, `Raison Sociale : ${this.donnerDesFournisseur[0]["resultat"]["dataSociete"]['Raison']}`, "raisonConsommation", [1, 1, 1])
            if (adresseSocieteSeparer[0] != "") {
              await this.createTextFieldPers(11, [0.10, 0.17, 0.35], true, form, page, 510, 415, 250, 40, `Adresse : ${adresseSocieteSeparer[1]}`, "Adresse", [1, 1, 1])
              await this.createTextFieldPers(11, [0.10, 0.17, 0.35], true, form, page, 510, 385, 250, 40, `Code postal : ${adresseSocieteSeparer[0]}`, "Code_postal", [1, 1, 1])
              await this.createTextFieldPers(11, [0.10, 0.17, 0.35], true, form, page, 510, 365, 250, 40, `Commune : ${adresseSocieteSeparer[2]}`, "Commune", [1, 1, 1])
            } else {
              await this.createTextFieldPers(11, [0.10, 0.17, 0.35], true, form, page, 510, 435, 250, 40, `Adresse postale : ${adressePostal}`, "Adresse", [1, 1, 1])
            }
          }
          
          //prepare l'entete de consommation
          var enteteInfoCompteur = {
            xBegin: 80,
            yBegin: 460,
            data: [
              {
                text: [
                  {
                    fieldName: 'pdl',
                    title: 'Compteur',
                    value: numCompteur + "",
                  },
                  {
                    fieldName: 'dateDebut',
                    title: 'Déb.contrat ',
                    value: this.getDateFormatSlach(donnerCompteur['dateDebut']),
                  }
                ],
                width: 200,
                height: 32,
                fieldName: "titleInfo",
              },
              {
                text: [
                  {
                    fieldName: 'kvaBASE',
                    title: 'Tarif',
                    value: donnerCompteur['tarif'] + "",
                  },
                ],
                width: 64,
                height: 32,
                fieldName: "Tarif",
              },
              {
                text: [
                  {
                    fieldName: 'carBASE',
                    title: 'Profil',
                    value: donnerCompteur['profilCompteur'] + "",
                  },
                ],
                width: 64,
                height: 32,
                fieldName: "titleCar",
              },
              {
                text: [
                  {
                    fieldName: 'Total',
                    title: 'CAR MWh',
                    value: donnerCompteur['CA'] + "",
                  },
                ],
                width: 64,
                height: 32,
                fieldName: "titleTotal",
              }
            ]
          }
          pdfDoc = await this.infoTable(form, pdfDoc, page, enteteInfoCompteur)
          //preparation des titre pour les offre principal
          this.Offres = {
            actuelle: fournisseurActuelle,
            xBegin: 130,
            yBegin: 140,
            titles: [
              {
                title: "Durée",
                subTitle: "",
                width: 64,
                pas: 2,
                fieldName: "BigDurre",
              },
              {
                title: "Type d'offre",
                subTitle: "",
                width: 64,
                pas: 2,
                fieldName: "BigTypeOffre",
              },
              {
                title: "Budget Energie",
                subTitle: "en €/an",
                width: 64,
                pas: 2,
                fieldName: "BigPrix",
              },
              {
                title: "Frais de distribution",
                subTitle: "en €/an",
                width: 66,
                pas: 2,
                fieldName: "BigARTD",
              },
              {
                title: "CEE",
                subTitle: "en €/an",
                width: 64,
                pas: 2,
                fieldName: "BigCEE",
              },
              {
                title: "Abonnement",
                subTitle: "en €/an",
                width: 64,
                pas: 2,
                fieldName: "BigABO",
              },
              {
                title: "Taxe (TICGN)",
                subTitle: "en €/an",
                width: 64,
                pas: 2,
                fieldName: "BigTICGN",
              },
              {
                title: "CTA",
                subTitle: "en €/an",
                width: 64,
                pas: 2,
                fieldName: "BigCTA",
              },
              {
                title: "Budget Annuel",
                subTitle: "en €/an",
                width: 64,
                pas: 2,
                fieldName: "BigBudget",
              },
            ],
            data: [
            ]
          }
          // create line for fournisseur actuelle
          if (fournisseurActuelle == true) {
            if (!isNaN(fournisseur["resultat"]["result"][0]['cee']) && !isNaN(donnerCompteur['CA'])) {
              var Budgetcee = fournisseur["resultat"]["result"][0]['cee'] * donnerCompteur['CA'];
            } else {
              var Budgetcee = 0;
            }

            var OffreActuelle = {
              xBegin: 130,
              yBegin: 330,
              logo: "/logoParticulier/" + fournisseur["resultat"]["result"][0]['logo'],
              data: [
                {
                  title: "Durée",
                  subTitle: "",
                  value: fournisseur["resultat"]["result"][0]['DUREE'] + " MOIS",
                  fieldName: "ActuDuree",
                  width: 64,
                  pas: 2,
                },
                {
                  title: "Type d'offre",
                  subTitle: "",
                  value: fournisseur["resultat"]["result"][0]['Offre'] + "",
                  fieldName: "ActuTypeOffre",
                  width: 64,
                  pas: 2,
                },
                {
                  title: "Budget Energie",
                  subTitle: "en €/MWh",
                  value: fournisseur["resultat"]["result"][0]['prixMolucule'] * donnerCompteur['CA'] + "",
                  fieldName: "ActuPrix",
                  width: 64,
                  pas: 2,
                },
                {
                  title: "Frais de distribution",
                  subTitle: "en €/an",
                  value: fournisseur["resultat"]["result"][0]['ARTD'] * donnerCompteur['CA'] + "",
                  fieldName: "ActuARTD",
                  width: 66,
                  pas: 2,
                },
                {
                  title: "CEE",
                  subTitle: "en €",
                  value: Budgetcee + "",
                  fieldName: "Actucee",
                  width: 64,
                  pas: 2,
                },
                {
                  title: "Abonnement",
                  subTitle: "en €/an",
                  value: fournisseur["resultat"]["result"][0]['ABO'] + "",
                  fieldName: "ActuAbonnement",
                  width: 64,
                  pas: 2,
                },
                {
                  title: "Taxe (TICGN)",
                  subTitle: "en €/ans",
                  value: fournisseur["resultat"]["result"][0]['TICGN'] * donnerCompteur['CA'] + "",
                  fieldName: "ActuTICGN",
                  width: 64,
                  pas: 2,
                },
                {
                  title: "CTA",
                  subTitle: "en €/an",
                  value: fournisseur["resultat"]["result"][0]['CTA'] + "",
                  fieldName: "ActuCTA",
                  width: 64,
                  pas: 2,
                },
                {
                  title: "Budget Annuel",
                  subTitle: "en €/an",
                  value: fournisseur["resultat"]["result"][0]['budget'] + "",
                  fieldName: "Actubudget",
                  width: 64,
                  pas: 2,
                },
              ]
            }
            pdfDoc = await this.drawOffreActuelle(form, pdfDoc, page, OffreActuelle)
            this.Offres.titles.push(
              {
                title: "Economies par rapport à votre offre",
                subTitle: "en %/an",
                width: 85,
                pas: 3,
                fieldName: "BigPourcentage",
              }
            )
          }

          /////////////////////////////////////////////////////////////
          //ajouter ptout les proposition dans les offres a afficher
          var pos_debut = 0
          if(fournisseurActuelle == true){
            pos_debut = 1
          }
          for (var index = pos_debut; index < listDesOffre.length; index++) {

            if (!isNaN(listDesOffre[index]['cee']) && !isNaN(donnerCompteur['CA'])) {
              var Budgetcee = listDesOffre[index]['cee'] * donnerCompteur['CA'];
            } else {
              var Budgetcee = 0;
            }
            var item = [
              {
                value: "/logoParticulier/" + listDesOffre[index]['logo'],
                fieldName: "logo",
                width: 64,
                pas: 2,
              },
              {
                value: listDesOffre[index]['DUREE'] + " MOIS",
                fieldName: "ActuDuree",
                width: 64,
                pas: 2,
              },
              {
                value: listDesOffre[index]['Offre'] + "",
                fieldName: "ActuTypeOffre",
                width: 64,
                pas: 2,
              },
              {
                value: listDesOffre[index]['prixMolucule'] * donnerCompteur['CA'] + "",
                fieldName: "ActuPrix",
                width: 64,
                pas: 2,
              },
              {
                value: listDesOffre[index]['ARTD'] * donnerCompteur['CA'] + "",
                fieldName: "ActuARTD",
                width: 64,
                pas: 2,
              },
              {
                value: Budgetcee + "",
                fieldName: "Actucee",
                width: 64,
                pas: 2,
              },
              {
                value: listDesOffre[index]['ABO'] + "",
                fieldName: "ActuAbonnement",
                width: 64,
                pas: 2,
              },
              {
                value: listDesOffre[index]['TICGN'] * donnerCompteur['CA'] + "",
                fieldName: "ActuTICGN",
                width: 64,
                pas: 2,
              },
              {
                value: listDesOffre[index]['CTA'] + "",
                fieldName: "ActuCTA",
                width: 64,
                pas: 2,
              },
              {
                value: listDesOffre[index]['budget'] + "",
                fieldName: "Actubudget",
                width: 64,
                pas: 2,
              },

            ]
            // ajouter champ economies si ilya un fournisseur actuelle
            if (fournisseurActuelle == true) {
              try {
                var poucentage = ((listDesOffre[0]['budget'] - listDesOffre[index]['budget']) / listDesOffre[0]['budget']) * 100
                poucentage = poucentage.toFixed(2)
              } catch (error) {
                var poucentage = 0.0
              }
              item.push(
                {
                  value: poucentage < 0 ? "--" : poucentage + "",
                  fieldName: "pourcentage",
                  width: 70,
                  pas: 5,
                }
              )
            }
            this.Offres.data.push(item)
          }
          var res = await this.drawOffres(form, pdfDoc, page, this.Offres, fournisseurActuelle)
          //create pagfe pour les recap

          var page = pdfDoc.addPage([842, 595])
          var form = pdfDoc.getForm()
          //preparation des titres pour les recap 
          this.OffresRecap = {
            actuelle: fournisseurActuelle,
            xBegin: 192,
            yBegin: 140,
            titles: [
              {
                title: "Prix Molecule",
                subTitle: "en €/MWh",
                width: 70,
                pas: 2,
                fieldName: "BigPrix",
              },
              {
                title: "Frais de distribution",
                subTitle: "en €/MWh",
                width: 66,
                pas: 2,
                fieldName: "BigARTD",
              },
              {
                title: "CEE",
                subTitle: "en €/MWh",
                width: 64,
                pas: 2,
                fieldName: "BigCEE",
              },
              {
                title: "Abonnement",
                subTitle: "en €/mois",
                width: 64,
                pas: 2,
                fieldName: "BigABO",
              },
              {
                title: "Taxe (TICGN)",
                subTitle: "en €/MWh",
                width: 64,
                pas: 2,
                fieldName: "BigTICGN",
              },
              {
                title: "CTA",
                subTitle: "en €/mois",
                width: 64,
                pas: 2,
                fieldName: "BigCTA",
              },
              {
                title: "Budget Annuel",
                subTitle: "en €/an",
                width: 64,
                pas: 2,
                fieldName: "BigBudget",
              },
            ],
            data: [
            ]
          }

          //ajouter tout les proposition dans les offres Recap a afficher
          for (var index = 0; index < listDesOffre.length; index++) {
            if (!isNaN(listDesOffre[index]['cee'])) {
              var cee = listDesOffre[index]['cee']
            } else {
              var cee = 0;
            }
            var item = [
              {
                value: "/logoParticulier/" + listDesOffre[index]['logo'],
                fieldName: "logo",
                width: 70,
                pas: 2,
              },
              {
                value: listDesOffre[index]['prixMolucule'] + "",
                fieldName: "recapPrix",
                width: 70,
                pas: 2,
              },
              {
                value: listDesOffre[index]['ARTD'] + "",
                fieldName: "recapARTD",
                width: 64,
                pas: 2,
              },
              {
                value: cee + "",
                fieldName: "recapcee",
                width: 64,
                pas: 2,
              },
              {
                value: listDesOffre[index]['ABO'] / 12 + "",
                fieldName: "recapAbonnement",
                width: 64,
                pas: 2,
              },
              {
                value: listDesOffre[index]['TICGN'] + "",
                fieldName: "recapTICGN",
                width: 64,
                pas: 2,
              },
              {
                value: listDesOffre[index]['CTA'] / 12 + "",
                fieldName: "recapCTA",
                width: 64,
                pas: 2,
              },
              {
                value: listDesOffre[index]['budget'] + "",
                fieldName: "recapbudget",
                width: 64,
                pas: 2,
              },

            ]
            this.OffresRecap.data.push(item)
          }

          var res = await this.drawOffresRecap(form, pdfDoc, page, this.OffresRecap, fournisseurActuelle)
          pdfDoc = res[0]
          page = res[1]
          var yBegin = res[2]
          for (var i = 0; i < listDesOffre.length; i++) {
            fournisseurList[i] = listDesOffre[i]['fournisseur'].toLowerCase() + '.pdf'
          }
          if (fournisseurActuelle == true) {
            fournisseurList.push(fournisseur["resultat"]["result"][0]['fournisseur'].toLowerCase() + '.pdf')
          }
        }
        fournisseurList = this.filterArray(fournisseurList)
        for (var i = 0; i < fournisseurList.length; i++) {
          var name = fournisseurList[i]
          try {
            const url4 = '/newFournisseurs/' + name
            const fournisseurPdfBytes = await fetch(url4).then((res) =>
              res.arrayBuffer()
            )

            const fournisseurPdfDoc = await PDFDocument.load(fournisseurPdfBytes)
            var loadedPage = fournisseurPdfDoc.getPages()[0];
            var imageBorder = await this.getImage("/imagePres/border-presentation.png", fournisseurPdfDoc)
            loadedPage.drawImage(imageBorder, {x: 767, y: 0, width: 85,height: 85})
            pdfDoc = await this.addPagesToPdf(pdfDoc, fournisseurPdfDoc)
          } catch (error) {
            console.log("Presentation Fournisseur introuvable " + name)
          }
        }
        const presentationURLPartFinal = '/pdf/Presentation-11-13.pdf'
        const presentationPartFinalBytes = await fetch(presentationURLPartFinal).then((res) =>
          res.arrayBuffer()
        )
        var presentationPagePartFinalDoc = await PDFDocument.load(presentationPartFinalBytes)

        var getPages = presentationPagePartFinalDoc.getPages()
        const timesRomanFont = await presentationPagePartFinalDoc.embedFont(StandardFonts.HelveticaBold)
        const contactPage = getPages[2]
        var username = localStorage.getItem('email')
        var nomPrenom = localStorage.getItem('vendeurName')
        var Telephone = localStorage.getItem('Telephone')
        const Helvetica = await presentationPagePartFinalDoc.embedFont(StandardFonts.Helvetica)

        contactPage.drawText(nomPrenom, {
          x: 305,
          y: 270,
          size: 30,
          font: timesRomanFont,
          color: rgb(1, 1, 1),
        })
        if (Telephone != null && Telephone != "null") {
          contactPage.drawText(Telephone, {
            x: 305,
            y: 230,
            size: 25,
            font: Helvetica,
            color: rgb(1, 1, 1),
          })

        }
        const createPageLinkAnnotation = (PDFPage, string) => {
          PDFPage.doc.context.register(
            page.doc.context.obj({
              Type: 'Annot',
              Subtype: 'Link',
              Rect: [0, 30, 40, 230],
              Border: [0, 0, 2],
              C: [0, 0, 1],
              A: {
                Type: 'Action',
                S: 'URI',
                URI: PDFString.of(`mailto:${string}`),
              },
            }),
          );
        }
        contactPage.drawText(username, {
          x: 305,
          y: 190,
          size: 20,
          font: Helvetica,
          color: rgb(1, 1, 1),
        });

        const link = createPageLinkAnnotation(contactPage, username);
        contactPage.node.set(PDFName.of('Annots'), presentationPagePartFinalDoc.context.obj([link]));

        pdfDoc = await this.addPagesToPdf(pdfDoc, presentationPagePartFinalDoc)
        const pdfBytes = await pdfDoc.save()
        this.generatedPdf = pdfBytes
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();

        today = mm + '/' + dd + '/' + yyyy;
        download(pdfBytes, 'Presentation_' + this.donnerDesFournisseur[0]["resultat"]["dataSociete"]['siret'] + '_' + today + '.pdf', 'application/pdf')
      }
      this.loadingPres = false;
      this.dialogPres = false;
    }
  }

}
</script>
