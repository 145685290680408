<template>
<v-btn @click='run'>
    test
</v-btn>
</template>

<script>

import axios from 'axios'
import download from 'downloadjs'

import { degrees, PDFDocument, rgb, StandardFonts,layoutMultilineText } from 'pdf-lib'
export default {
    name: 'nextTest',
    data: () => ({
        
        dialog: false,
        error: false,
        loading: false,
        messageDialoge: "",
        energieVert: true,
        percent: '25',
        generatedPdf: null,
        type: '',
            
        data:{
            dateAjourdhui: new Date().getDate()+"/"+new Date().getMonth()+1+"/"+new Date().getFullYear(),
            dateDebut: "10/10/2020",
            dateFin: "10/10/2022",
            pdl: "14552022144555",
            siret: "11111111111111", 
            codeNaf: "1212Z", 
            adresseSociete: "199 RUE, 12554 commune d'atte", 
            adresseCompteur: "199 RUE, 12554 commune d'atte",
            codePostaleCompteur: '12554',
            voieCompteur: 'sddsdsd',
            communeCompteur: 'sdsdsd',
            codePostaleSociete: 'sdsdsd',
            voieSociete: 'sdsdds',
            communeSociete: 'sdsdsd',
            codePostale: 'sdsdsd',
            nom: "chrif",
            prenom: "slimen", 
            email: "slimenc6@gmail", 
            numTele: "0755448899", 
            consommationAnuuelle: "377.20",
            typeCompteur: "BTINFMUDT",
            kva: "24",
            raison: "Gaz elec moins cher Gaz elec moins cher Gaz elec moins cher",
            nbrMonth: '2',
            cabase: '25',
            cahp: '10',
            cahc: '10',
        },
    }),
  methods: {
        /*async getAdresseCompteur(){
            this.data.dateAjourdhui= new Date().getDate()+"/"+new Date().getMonth()+1+"/"+new Date().getFullYear()
            if(this.donnerEnedis.donneTechniqueInfo[0].result == 'valide'){
                this.data.adresseCompteur = this.donnerEnedis.donneTechniqueInfo[0].data.numVoie+' '+
                        this.donnerEnedis.donneTechniqueInfo[0].data.codePostal+' '+this.donnerEnedis.donneTechniqueInfo[0].data.commune

            }
            this.data.dateDebut = this.donner.dateDebut+""
            this.data.dateFin = this.donner.dateFin+""
            this.data.pdl = this.numCompteur+""
            this.data.siret = this.societe.siret+''
            this.data.adresseSociete = this.societe.AdressePostal+""
            this.data.nom = this.societe.nom+""
            this.data.prenom = this.societe.prenom+""
            this.data.email = this.societe.AdresseEmail+""
            this.data.numTele = this.societe.numTele+""
            this.data.consommationAnuuelle = this.donner.consomationGlobale+""
            this.data.kva = this.donnerEnedis.donneTechniqueInfo[0].data.kva+""
            this.data.raison = this.societe.Raison+""
            this.data.nbrMonth = this.donner.nbrMonth+""
            this.data.typeCompteur = this.donnerEnedis.donneTechniqueInfo[0].data.typeCompteur
            this.data.codeNaf = this.societe.codeNaf               
            this.data.codePostaleCompteur = this.donnerEnedis.donneTechniqueInfo[0].data.codePostal
            this.data.voieCompteur = this.donnerEnedis.donneTechniqueInfo[0].data.numVoie
            this.data.communeCompteur = this.donnerEnedis.donneTechniqueInfo[0].data.commune
            this.data.codePostaleSociete = this.societe.codePostal
            this.data.voieSociete = this.societe.Voie
            this.data.communeSociete = this.societe.commune
        },*/
        async run(){
            //await getAdresseCompteur()
            await axios({
                url: 'https://editionContrat.gaz-elec-moinscher.fr/edsContrat',
                method: 'POST',
                data: this.data,
                headers:{'content-type': 'application/json'}
                
            })
            .then(async (res) => {
                //solution one to download the page
                
                /*const linkSource = `data:application/pdf;base64,${res.data}`;
                const downloadLink = document.createElement("a");
                const fileName = "vct_illustration.pdf";
                downloadLink.href = linkSource;
                downloadLink.download = fileName;
                downloadLink.click();*/

                
                //solution two to download the page
                var doc = await PDFDocument.load(res.data)
                doc = await doc.save()
                download(doc, 'EDS__.pdf', 'application/pdf')
            }).catch((err)=>{
                console.log(err)
            })
      }
  }
}

</script>
