<template>
  <div>
      <loadingComponent v-if="dialog==true" @closeDialog='dialog = $event' :dialogProps="dialog" :messageDialogeProps="messageDialogeError" :loadingProps="loading" :errorProps="error" />
     
      <div>
          <v-btn
          @click="createContrat"
          icon
          depressed class="ma-auto text-capitalize">
              <v-icon  :color="$colors[0]">mdi-text-box-edit</v-icon>
          </v-btn>
      </div>
  </div>
</template>
<script>
  import { degrees, PDFDocument, rgb, StandardFonts,layoutMultilineText, setFillingRgbColor, setFontAndSize  } from 'pdf-lib'
  import download from 'downloadjs'
  import jsPDF from 'jspdf'
  import 'jspdf-autotable'

  import loadingComponent from "../../components/ui/loading-component.vue"
  import axios from 'axios'
  export default{
      name: "JpmeContrat",
      components:{loadingComponent},
      props: {
          donner: null,
          numCompteur: null,
          societe: null,
          donnerEnedis: null,
          optionTarifaire: null,
      },
      data() {
          return {
              dialog: false,
              error: false,
              loading: false,
              messageDialogeError: "",
              messageDialogeSucces:"",
              data:{
                  dateAjourdhui: new Date().getDate()+"/"+(parseInt(new Date().getMonth())+1)+"/"+new Date().getFullYear(),
                  dateDebut: "10/10/2020",
                  dateFin: "10/10/2022",
                  pdl: "14552022144555",
                  siret: "11111111111111", 
                  codeNaf: "1212Z", 
                  adresseSociete: "199 RUE, 12554 commune d'atte", 
                  adresseCompteur: "199 RUE, 12554 commune d'atte",
                  codePostaleCompteur: '',
                  voieCompteur: '',
                  communeCompteur: '',
                  codePostaleSociete: '',
                  voieSociete: '',
                  communeSociete: '',
                  
                  codePostale: '',
                  nom: "chrif",
                  prenom: "slimen", 
                  email: "slimenc6@gmail", 
                  numTele: "0755448899", 
                  consommationAnuuelle: "377.20",
                  typeCompteur: "BTINFMUDT",
                  kva: "24",
                  raison: "Gaz elec moins cher Gaz elec moins cher Gaz elec moins cher",
                  nbrMonth: '2',
                  cabase: '',
                  cahp: '',
                  cahc: '',
              },
          }

      },
      methods:{
          
          async createContrat(){
              this.addData = false
              this.dialog= true
              this.error= false
              this.loading= true
              this.messageDialogeError= ""
              this.messageDialogeSucces=""
              var dataToSend = {
                  donner: this.donner,
                  donnerEnedis: this.donnerEnedis,
                  optionTarifaire: this.optionTarifaire,
                  numCompteur: this.numCompteur,
                  societe: this.societe,
              }
              await axios({
                  url: process.env.VUE_APP_URL_EDITION_CONTRAT + '/JpmeContrat',
                  method: 'POST',
                  data: dataToSend,
                  headers:{'content-type': 'application/json'}
                  
              })
              .then(async (res) => {
                  var doc = await PDFDocument.load(res.data)
                  doc = await doc.save()
                  download(doc, this.numCompteur+'_JPME_'+this.societe.Raison+'_.pdf', 'application/pdf')
              }).catch((err)=>{
                  console.log(err)
              })
              this.dialog= false
              this.error= false
              this.loading= false
              this.messageDialogeError= ""
              this.messageDialogeSucces=""
              this.$emit('readonlyExpand',false)
          },
      }
  
  }
</script>
