<template>
  <div>
    <div v-if="!this.typeContrat">
      <v-card
        elevation="6"
        height="30vh"
        min-height="400px"
        width="40vw"
        min-width="350px"
        class="mx-auto d-flex flex-column justify-space-around mt-12"
      >
        <v-card-title class="justify-start ml-md-4  ml-xl-6">
          <v-row>
            <v-col cols="12">
              <p
                class="text-xl-h4 text-lg-h5 text-md-h5 text-sm-subtitle-1 text-xs-subtitle-1"
              >
                Veuillez choisir le type de client
              </p>
            </v-col>
          </v-row>
        </v-card-title>

        <v-card-actions>
          <v-row justify="space-around" justify-xl="center">
            <v-col
              cols="12"
              sm="12"
              md="4"
              xl="3"
              class="d-flex justify-xs-center justify-sm-center justify-md-end justify-lg-end justify-xl-start"
            >
              <v-checkbox
                v-model="checkboxProfessionnel"
                @change="checkboxParticulier = !checkboxProfessionnel"
                class="radio"
                label="Professionnel"
              />
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="4"
              xl="3"
              class="d-flex justify-xs-center justify-sm-center justify-md-end justify-xl-end"
            >
              <v-checkbox
                v-model="checkboxParticulier"
                @change="checkboxProfessionnel = !checkboxParticulier"
                class="radio"
                label="Particulier"
              />
            </v-col>
          </v-row>
        </v-card-actions>

        <v-card-actions class="d-flex justify-end">
          <v-row>
            <v-spacer></v-spacer>
            <v-col
              class="d-flex justify-center"
              cols="12"
              sm="12"
              md="6"
              lg="5"
              xl="4"
            >
              <v-btn
                depressed
                elevation="2"
                large
                color="primary"
                style="textTransform: none"
                width="80%"
                @click="handleTypeContratButtonClick"
              >
                Suivant
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </div>
    <!--<div v-if="this.typeContrat === 'pro'">
      <v-card
        elevation="6"
        height="30vh"
        min-height="400px"
        width="40vw"
        min-width="350px"
        class="mx-auto d-flex flex-column justify-space-around mt-12"
      >
        <v-card-title class="justify-start ml-md-4  ml-xl-6">
          <v-row>
            <v-col cols="12">
              <p
                class="text-xl-h4 text-lg-h5 text-md-h5 text-sm-subtitle-1 text-xs-subtitle-1"
              >
                Avez vous une ACD?
              </p>
            </v-col>
          </v-row>
        </v-card-title>

        <v-card-actions>
          <v-row justify="space-around" justify-xl="center">
            <v-col
              cols="12"
              sm="12"
              md="4"
              xl="3"
              class="d-flex justify-xs-center justify-sm-center justify-md-end justify-lg-end justify-xl-start"
            >
              <v-checkbox
                v-model="checkboxACDOui"
                @change="checkboxACDNon = !checkboxACDOui"
                class="radio"
                label="Oui"
              />
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="4"
              xl="3"
              class="d-flex justify-xs-center justify-sm-center justify-md-end justify-xl-end"
            >
              <v-checkbox
                v-model="checkboxACDNon"
                @change="checkboxACDOui = !checkboxACDNon"
                class="radio"
                label="Non"
              />
            </v-col>
          </v-row>
        </v-card-actions>

        <v-card-actions class="d-flex justify-end">
          <v-row>
            <v-col
              class="d-flex justify-center"
              cols="12"
              sm="12"
              md="6"
              lg="5"
              xl="4"
            >
              <v-btn
                large
                plain
                color="primary"
                style="textTransform: none"
                @click="handlePrecedantClick"
              >
                Précédent
              </v-btn>
            </v-col>
            <v-spacer></v-spacer>
            <v-col
              class="d-flex justify-center"
              cols="12"
              sm="12"
              md="6"
              lg="5"
              xl="4"
            >
              <v-btn
                depressed
                elevation="2"
                large
                color="primary"
                style="textTransform: none"
                width="80%"
                @click="handleACDButtonClick"
              >
                Démarrer
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </div>-->
  </div>
</template>

<script>
export default {
  data() {
    return {
      typeContrat: null,
      checkboxProfessionnel: true,
      checkboxParticulier: false,
      checkboxACDNon: false,
      checkboxACDOui: true,
    }
  },
  created() { 
    this.testUserType()
  },
  methods: {
    testUserType(){
      var typeUser = localStorage.getItem('typeUser')
      
      if(typeUser === 'part'){
        this.$router.push({ path: '/nouvelle-opportunite/elec-particuliers' })
      }
    },
    handleTypeContratButtonClick() {
      if (this.checkboxProfessionnel) {
        this.$router.push({ path: '/nouvelle-opportunite/demande-cotation' })
      }

      if (this.checkboxParticulier) {
        this.$router.push({ path: '/nouvelle-opportunite/elec-particuliers' })
      }
    },/*
    handleACDButtonClick() {
      if (this.checkboxACDOui) {
        this.$router.push({ path: '/nouvelle-opportunite/demande-cotation' })
      }

      if (this.checkboxACDNon) {
        this.$router.push({
          path: '/nouvelle-opportunite/autorisation-collecte-donnees',
        })
      }
    },*/
    handlePrecedantClick() {
      this.typeContrat = null
    },
  },
}
</script>

<style>
label.v-label {
  width: 100%;
  white-space: nowrap;
}

.radio {
  width: 90%;
}
</style>
