import './installCompositionApi.js'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueCookies from 'vue-cookies'
import vuetify from './plugins/vuetify'
import store from "./store/index"
import dayjs from 'dayjs'


//import tokenControle from './plugins/tokenControle.js'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import Toast, { POSITION } from "vue-toastification";
import "vue-toastification/dist/index.css";

import axios from 'axios'

Vue.config.productionTip = false
Vue.use(store);
Vue.use(VueCookies)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
const MyPlugin = {
  install(Vue, options) {
    Vue.prototype.setToken = (keyName, keyValue, ttl) => {
      const data = {
          value: keyValue,              
          ttl: Date.now() + (ttl * 1000),   
      }
   
      localStorage.setItem(keyName, JSON.stringify(data));
  }
  Vue.prototype.$colors = ["#000", "#1E6CA4", "#FFCB35"];
  Vue.prototype.$dayjs = dayjs;
  Vue.prototype.getToken = (keyName) => {
      const data = localStorage.getItem(keyName);
      if (!data) {
          return null;
      }
      const item = JSON.parse(data);
      if (Date.now() > item.ttl) {
          localStorage.removeItem(keyName);
          return null;
      }
      return item.value;
  }
    Vue.prototype.globalHelper = () => {
      alert("Hello world")
    }
    
    Vue.prototype.$rules = { 

      telMobileIsValid(tel) {
        tel = tel.replace(/[\.,\s]/g, '')

        return (
          /^(\+33|0033|0)(6|7)[0-9]{8}$/g.test(tel) ||
          `Veuillez saisir un numéro de téléphone commençant par 06 ou 07.`
        )
      },
      emailIsValid(mail) {
        return !!mail && /.+@.+\.+.+/.test(mail) || `Veuillez saisir un e-mail valide.`
      },
        
     

      valeurMin: (v) => (v >= 0) || "Ce champ n'accepte qu'une valeur positive.",
      ssLength: v => v.length == 13 || 'le numéro de sécurité sociale doit comporter 13 chiffres',
      cleLength: v => v.length == 2 || 'la cle de sécurité sociale doit comporter 2 chiffres',     
      phoneisNumber: (v) =>
        v.match(/^[0-9]+$/) != null || "Ce champ n'accepte que les chiffres", 
      value: (val) => val>=0 || "La valeur de ce champs ne peut pas etre inférieur à zéro",
      isSiren: (v) =>
          v.length == 9 && v.match(/^[0-9]+$/) != null|| 'Le siren doit être un nombre de 9 chiffres',
      phoneLength: (v) =>
          v.length == 10 ||
          'Le numéro de téléphone ne doit pas dépasser les 10 chiffres',     
      isString: value => !(/\d/.test(value)) || "Ce champ ne peut pas etre composé de chiffres",
      noWhiteSpace: value => !(/\s/.test(value)) || "Ce champ ne doit pas être constitué d'espace",
      minPasswordLength: v => v.length >= 8 || 'Le mot de passe doit comporter un minimum de 8 caractères',
      frenchPhone: v =>(/^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/).test(v) || 'Le numéro de téléphone est incorrect',
      emailRules:v => (/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/).test(v) || 'E-mail invalide',   
      isPositiveNumb:v=> v>0 || "La valeur doit etre strictement supérieur à 0",
      siretLength: v => v.length == 14 || 'Le siret doit comporter 14 chiffres',
      isMonthCorrect: v => (v >= 1 && v <= 12) || "Le mois doit etre compris entre 1 et 12 ",
      isNumber: v => v.match(/^[0-9]+$/) != null || "Ce champ n'accepte que les chiffres",
      compteurElecLength: v => v.length == 14 || 'Le RAE / PDL est une suite de 14 chiffres',
      isGazCompteur: v => (v.match(/^(GI)[0-9]{6}$/) || (v.length == 14 && v.match(/^[0-9]+$/) != null)) || 'Le numéro PCE/ PDL doit commencer par (GI) ou comporter 14 chiffres',
      margeMaxMin: v => (v<=25 && v>=2) || 'La marge doit être comprise entre 2 et 25',
      Length: v=> (v.length>0) || "Veuillez saisir un type d'offre",
      isBetweenRangeNumber : v => (v>=2 && v<=25) || "La valeur saisie doit etre comprise entre 2 et 25",
      required: value => !!value || 'Ce champ est obligatoire.',
      requiredLength: value => value.length > 0 || 'Ce champ est obligatoire.',
      //isMonthCorrect: v => v.match(/^([1-9]|1[012])$/) || "Le mois doit etre compris entre 1 et 12 ",
      sirenLength: v => v.length == 9 || 'Le siren doit comporter 9 chiffres',
      
  },
    Vue.prototype.whoIAm = async (token) => {
      var formData = new FormData()
      formData.append('token', token)
      var typeUser = "";
      await axios({
          url:
          process.env.VUE_APP_URL_API_CLIENT +
          'whoIam/',
          method: 'POST',
          data: formData,
      })
      .then((res) => {
        typeUser =  res.data.result
      }).catch((err)=>{
        console.log(err)
        typeUser = "noToken"
      })
      return typeUser
  }
  },
}
Vue.use(MyPlugin)
const options = {
  position: POSITION.TOP_RIGHT
};
Vue.use(Toast, options);
new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app')
